
import * as React from 'react';

// Components
import ImageZoom from './ImageZoom';

/**
 * @name Introduction
 * @description Component with content for Introduction section
 * @returns React Component
 */
function Introduction() {

    // Project root path
    const path = process.env.SERVER_PATH;

    // Images and image data
    const IntroImage_1984_data = {
        src: `${path}resources/artistImages/introImage_1984.svg`,
        alt: "Cartoon TV with calls for violence",
        ref: React.useRef(null) 
    };    

    return (
        <>   
            <div className="section_anchor" id="Introduction"></div>
            <div className="section_main_wrapper">
                
                <section className="introduction">

                    <div className="title">Introduction</div>    
                    <h2 className="subtitle">
                        Why tell the story of the 1984 Ghallughara (Genocide)
                    </h2>               

                    <div className="content">

                        <div className="why_tell">

                            <ImageZoom 
                                img_src={IntroImage_1984_data.src}
                                img_alt={IntroImage_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_IntroImage_1984'
                            />

                            <div className="spacer">&nbsp;</div>

                            <div className="text">

                                <p>
                                    This year marks the 37th anniversary of the 1984 genocidal massacre of Sikhs in cities across India in 1984. Like previous anniversaries this one will pass without any justice being served in the courts of the largest democracy in the world. The killings that transpired starting October 31st, 1984 through the first days of November 1984 were the biggest massacre in independent India’s history. This fact alone should have ensured that every single Indian was educated about the state sanctioned violence that happened in 1984 and policies implemented that would never allow for those conditions to arise again.
                                </p>

                                <p>
                                    Unfortunately, none of this took place. There was no oversight, rather, a deliberate design to obscure and bury the truth. The political powers in India along with their deep tentacles in the security and judicial apparatus do not want this memory to live, do not want justice to be served and do not want their rapacious appetite for power to be checked by the principles of democracy. 
                                </p>

                                <p>
                                    The genocidal massacre of Sikhs in1984 is evidence that democratic governance does not prevent state sponsored violence against its own people. Democracy, in the hands of leaders with authoritarian tendencies, can indeed serve as a sophisticated tool to manufacture conditions for abuse of power, human rights and basic human dignity.
                                </p>

                                <p>
                                    As a survivor of this genocide, it is imperative I give voice to what transpired from the perspective of those who were targeted by the terror of the Indian government. I first created the illustrations on this page for a gallery exhibit in New York City to mark the 25th anniversary of this tragedy. 
                                </p>

                                <p>
                                    We must leave our testimony using the diverse mediums at our disposal. For me as a cartoonist, each word and image serve to illuminate the continued injustice of the world&#39;s largest &#34;democracy&#34;.
                                </p>

                            </div>

                        </div>              

                    </div>

                </section>

            </div>
        </>
    );
}

export default Introduction;
