/**
 * @name DisableRightClickAndDragOnImages.js
 * @file Disables context menu which appears with right clicking, but only
 * on images. Also disables dragging of image (mousedown).
 * @author Darren Brooks
 * @copyright 2021 Darren Brooks
 * 
 */
import {useEffect} from 'react';

function DisableRightClickAndDragOnImages() {

    /**
    * Prevent ability to bring up context menu and drag images.
    * Do not add empty [] as this must be able to run more than once
    * to allow React to load all images on a page.
    */
    useEffect(() => {
        const arr_temp = document.querySelectorAll('img');
        
        arr_temp.forEach(el => {
            el.addEventListener('contextmenu', e => e.preventDefault());
            el.addEventListener('mousedown', e => e.preventDefault());
        });
        
        return (() => {
            arr_temp.forEach(el => {
                el.removeEventListener('contextmenu', e => e.preventDefault());
                el.removeEventListener('mousedown', e => e.preventDefault());
            });
        });
    });

    return null;
}

export default DisableRightClickAndDragOnImages;
