import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @name useToggler
 * @description Stores boolean state of toggling, and returns the updated state.
 * React.useCallback is used to preserve the reference to one of the setter functions.
 * useCallback() prevents handleToggler function from being recreated on every render.
 * Best practices is to only use this on expensive methods, so I just did it on that
 * function for my own education. 
 * @param {boolean} initial_value false|true to indicate initial state of toggle.
 * @return all state and methods in an array
 */
function useToggler(initial_value = false) {
    
    const [toggle_state, setToggleState] = useState(initial_value);

    const handleToggler = useCallback((new_state = null) => {

        // Imperative setting of value
        new_state === false ? setToggleState(false) : setToggleState(true);
        
        // Default is to simply toggle value
        new_state === null && setToggleState(!toggle_state);
        
    }, [toggle_state]);

    const handleOpenToggler = () => {
        setToggleState(true);
    };

    const handleCloseToggler = () => {
        setToggleState(false);
    };

    /**
     * Return values in array so value names can be changed in case the script
     * utilizes more than one component that needs toggling.
     */
    return [toggle_state, handleToggler, handleOpenToggler, handleCloseToggler];
}

useToggler.propTypes = {
    initial_value: PropTypes.bool,
    new_state: PropTypes.bool
}

export default useToggler;
