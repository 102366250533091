
import * as React from "react";
import { HashLink } from 'react-router-hash-link';

// Contexts
import AboutPortal from './AboutPortal';

// Hooks
import useClickOutside from "../hooks/useClickOutside";
import useToggler from './../hooks/useToggler';
import useCreatePortalContainer from '../hooks/useCreatePortalContainer';
import usePortal from "../hooks/usePortal";
import useFocusTrap from "../hooks/useFocusTrap";

// Helper Fx
import { handleKeyDown } from "../functions/helperFunctions";

// Components
import BackToTopFab from './../components/BackToTopFab';

// import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function GlobalNavbar() { 

  /*///////////////////////
  * HAMBURGER MENU
  ///////////////////////*/

  /**
   * Ref value for hamburger menu component
   */
  const nav_ref = React.useRef(null);

  // Controls/State for Hamburger Button and Menu
  const [
    toggle_state_hamburger, 
    handleTogglerHamburger,
    handleOpenTogglerHamburger,
    handleCloseTogglerHamburger
  ] = useToggler(false);  

  // Hamburger menu icon ref
  const hamburgerButtonRef = React.useRef(null);
  const hamburgerButtonIconRef = React.useRef(null);
  const hamburger_button_container_ref = React.useRef(null);
  
  /**
   * @name handleOpenHamburgerMenu
   * @description Wrapper around handleOpenToggler method in
   * order to pass in an optional callback function to run
   * after menu is opened.
   * @param {event} e 
   * @param {function} callbackFx Function to run after menu
   * is opened.
   */
  const handleOpenHamburgerMenu = (e, callbackFx = null) => {
    handleOpenTogglerHamburger();
    if (callbackFx !== null) callbackFx();
  };// end handleOpenHamburgerMenu()

  /**
   * @name handleCloseHamburgerMenu
   * @description Wrapper around handleCloseToggler method in
   * order to pass in an optional callback function to run
   * after menu is closed.
   * @param {event} e 
   * @param {function} callbackFx Function to run after menu
   * is closed.
   */
  const handleCloseHamburgerMenu = (e, callbackFx = null) => {
    handleCloseTogglerHamburger();
    if (callbackFx !== null) callbackFx();
  };// end handleCloseHamburgerMenu()

  /**
   * @name handleKeyEventHamburgerMenu
   * @description Wrapper around key event method for hamburger menu
   * which Utilizes helperFunction.js method handleKeyDown()
   * to toggle hamburger menu state.
   * @param {event} e 
   */
  const handleKeyEventHamburgerMenu = (e) => {
    handleKeyDown(e, toggle_state_hamburger, handleTogglerHamburger);
  };// end handleKeyEventHamburgerMenu()

  /**
   * @name handleEscapeKey
   * @description This method is used on the hamburger menu items to handle
   * closing menu via Escape key.
   * @param {event} e keydown event
   * @param {function} callbackFx Function to run after hamburger menu closes
   */
  const handleEscapeKey = (e, callbackFx = null) => {
    if (e.key === "Escape") {     
      e.preventDefault();
      handleCloseHamburgerMenu();
      if (callbackFx !== null) callbackFx();
    }
  };// end handleEscapeKey()

  /**
   * @name useClickOutside
   * @description Close hamburger menu when open and user 
   * clicks anywhere outside the boundaries of it.
   * 
   */
  useClickOutside(nav_ref.current, (e) => {
    toggle_state_hamburger && handleCloseHamburgerMenu(e, hamburgerButtonRef.current.focus())
  });// end useClickOutside()

  /*///////////////////////////////
  * FOCUS TRAP FOR HAMBURGER MENU
  ///////////////////////////////*/

  const [parent_element_ham_menu, setParentElementHamMenu] = React.useState(null);
  const [is_enabled_ham_menu, setIsEnabledHamMenu] = React.useState(false);

  React.useEffect(() => {
    setParentElementHamMenu(document.querySelector('.nav_main'))
    setIsEnabledHamMenu(true);
  }, []);

  const fx_ham_menu = () => {
    toggle_state_hamburger && handleCloseHamburgerMenu(
        () => setTimeout(() => { hamburgerButtonRef.current.focus() }, 50)
    )
  };
  
  const obj_ham_menu = {
    parent_element: parent_element_ham_menu,
    handleCloseComponentFx: fx_ham_menu,
    is_enabled: is_enabled_ham_menu
  }
  
  // useFocusTrap(obj_focus_trap);
  const focus_trap_ham_menu = useFocusTrap(obj_ham_menu);

  /**
   * Accessibility: When hamburger menu opens, set focus
   * to first item.
   */
  React.useEffect(() => {
    toggle_state_hamburger && focus_trap_ham_menu.first_element.focus();
  }, [toggle_state_hamburger]);

  /*///////////////////////
  * ABOUT PORTAL
  ///////////////////////*/

  // Initialize usePortal
  const {
    is_portal_open,    
    handleOpenPortal,
    handleClosePortal,
    handleOnKeyDownPortal
  } = usePortal();

  /**
     * State to track if AboutPortal modal was opened via
     * 'click' or 'keydown' actions on the part of the user.
     * This is a simple way to change focus styles on the 
     * portal close button upon whether one or the other 
     * actions was taken. This is to satisfy a style preference
     * for subtler styling than the bolder outlines for 
     * accessibility.
     */
  const [is_click, setIsClick] = React.useState(false);

  /**
   * Use hook to create new DIV with custom settings,
   * and then save the return value reference.
   */
  const portal_location_about = useCreatePortalContainer({
      container_parent_element_id: 'globalPaper',
      container_element_id: 'about_portal_container',
      container_element_type: 'DIV',
  });

  const portal_location_about_ref = React.useRef(portal_location_about);  

  return (
    <>    
      <AboutPortal
        portal_location_ref={portal_location_about_ref}
        focus_on_close_ref={hamburgerButtonRef} 
        is_click={is_click}      
        is_portal_open={is_portal_open}
        handleOpenPortal={handleOpenPortal}
        handleClosePortal={handleClosePortal}
        handleOnKeyDownPortal={handleOnKeyDownPortal}
      />
      <div className="global_navbar">
        
        <div className="hamburger_button_container" ref={hamburger_button_container_ref}>

          {
            toggle_state_hamburger 
              ? <IconButton
                  variant="outlined" 
                  sx={{
                    color: 'var(--primary-background-color)',
                    // '&:hover': {
                    //   color: 'var(--primary-background-color)',
                    // },
                    // '&:focus': {
                    //   color: 'var(--primary-background-color)',
                    // }
                  }}
                  className="hamburger_button"
                  ref={hamburgerButtonRef}
                  aria-label="close hamburger menu"
                  aria-haspopup="true"
                  aria-expanded={toggle_state_hamburger ? "true" : "false"}
                  aria-controls="nav_main"
                  onClick={handleCloseHamburgerMenu}
                  onKeyDown={(e) => handleKeyEventHamburgerMenu(e)}
                >
                  <CloseIcon 
                    fontSize="large"                    
                    className="hamburger_button_icon"
                    ref={hamburgerButtonIconRef}
                  />
                </IconButton>
              : <IconButton
                  variant="outlined"
                  sx={{
                    color: '#bebfc4',
                      '&:hover': {
                        color: 'var(--primary-background-color)',
                      },
                      '&:focus': {
                        color: 'var(--primary-background-color)',
                      }                   
                  }}
                  className="hamburger_button"
                  ref={hamburgerButtonRef}
                  aria-label="open hamburger menu"
                  aria-haspopup="true"
                  aria-expanded={toggle_state_hamburger ? "true" : "false"}
                  aria-controls="nav_main"
                  onClick={handleOpenHamburgerMenu}
                  onKeyDown={(e) => handleKeyEventHamburgerMenu(e)}
                >
                  <MenuIcon 
                    fontSize="large"                    
                    className="hamburger_button_icon"
                    ref={hamburgerButtonIconRef}
                  />
                </IconButton>                                              
          }

        </div>

      </div>
      
      <nav 
        ref={nav_ref} 
        className={toggle_state_hamburger ? "nav_main active" : "nav_main"}
        aria-hidden={toggle_state_hamburger ? false : true}
      >
        <ul>
            <li>
              <HashLink smooth 
                to={`#Introduction`}                  
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                Introduction
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to={`#TheSetup`}
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                The Setup
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to={`#Massacre`} 
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                Massacre
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to={`#TheAftermath`}
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                The Aftermath
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to={`#AfterAllTheseYears`}
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                After All These Years
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to={`#Conclusion`} 
                tabIndex={toggle_state_hamburger ? "0" : "-1"} 
                onClick={handleCloseHamburgerMenu}
                onKeyDown={(e) => handleEscapeKey(
                  e,
                  () => { hamburgerButtonRef.current.focus() }
                )}
              >
                Conclusion
              </HashLink>
            </li>
            <li>
              <HashLink smooth 
                to="#"
                className="dialog_about_link"
                tabIndex={toggle_state_hamburger ? "0" : "-1"}
                // Close hamburger menu when opening About modal
                onClick={(e) => {
                  setIsClick(true);                  
                  handleOpenPortal(e, () => {
                    handleCloseHamburgerMenu();
                    // setTimeout(() => { btn_close_ref.current.focus() }, 50)
                  });

                }}
                onKeyDown={(e) => {
                  setIsClick(false);
                  handleOnKeyDownPortal(e, true, handleCloseHamburgerMenu());
                  handleEscapeKey(e,
                    () => { hamburgerButtonRef.current.focus() }
                  );
                }}               
              >
                About Vishavjit Singh
              </HashLink>
            </li>
        </ul>
      </nav>

      <BackToTopFab />      
    </>
  );
}

// GlobalNavbar.displayName = "GlobalNavbar";
// GlobalNavbar.propTypes = {
//   toggle_state_hamburger: PropTypes.bool.isRequired,
//   togglerHamburger: PropTypes.func.isRequired,
//   handleClick: PropTypes.func.isRequired,
//   handleKeyEvent: PropTypes.func.isRequired,
//   hamburgerButtonRef: PropTypes.node,
// }

export default GlobalNavbar;
