import * as React from "react";
import PropTypes from 'prop-types';

// function useCombinedRefs(...refs) {
//     const targetRef = React.useRef()
  
//     React.useEffect(() => {
//       refs.forEach(ref => {
//         if (!ref) return
  
//         if (typeof ref === 'function') {
//           ref(targetRef.current)
//         } else {
//           ref.current = targetRef.current
//         }
//       })
//     }, [refs])
  
//     return targetRef
// }

const useFocusTrap = (props = {
    parent_element: null, 
    handleCloseComponentFx: null,
    is_enabled: true,
}) => {

    const { 
        parent_element, 
        handleCloseComponentFx,
        is_enabled,
    } = props;

   
    const parent_element_ref = React.useRef(parent_element);

    React.useEffect(() => {        
        parent_element_ref.current = parent_element;
    }, [parent_element]);

    /**
     * Since handler will not change very often, if at all,
     * it is assigned to a ref to have it persist throughout
     * all renders.
     */
    const handleCloseComponentFx_ref = React.useRef(handleCloseComponentFx);

    /**
      * Update ref only when handler changes (arguable that
      * dependency is not needed b/c the handler won't change often
      * or at all, and the dependency adds comparison time to running
      * the useEffect. I think it's good to have the dependency to
      * show that I want it to run only when handler changes).
      */
    React.useEffect(() => {
        handleCloseComponentFx_ref.current = handleCloseComponentFx;        
    }, [handleCloseComponentFx]);

    const is_enabled_ref = React.useRef(is_enabled);
    React.useEffect(() => {
        is_enabled_ref.current = is_enabled;        
    }, [is_enabled]);

    const [focusable_elements, setFocusableElements] = React.useState([]);
    const [first_element, setFirstElement] = React.useState(null);
    const [last_element, setLastElement] = React.useState(null);

    /**
     * Upon page load, attach event listeners to hamburger
     * menu element as well as each focusable element in the
     * menu. The keyListener() method matches the keydown
     * event in the attached event listeners to the
     * keyListenersMap items. If e.key matches the Map item
     * key, the value, which is a function in this case, is
     * called.
     */
    React.useEffect(() => {

        if (!is_enabled) return;

        function internalHandler(e) {            
            return handleCloseComponentFx_ref.current(e);
        }

        /**
         * @name getFocusableElements
         * @description Convenience method for accessing the list of
         * focusable HTML elements in the parent HTML element.
         * @returns {object} Node list of focusable HTML elements within
         * the given parent component (labelled using useRef), and values
         * of the first and last elements in the list.
         */
        const getFocusableElements = () => {

            if (parent_element_ref.current !== null) {
                
                const focusables = parent_element_ref.current.querySelectorAll(
                    `input[type="password"]:not([disabled]),
                    a[href]:not([disabled]),
                    button:not([disabled]),
                    textarea:not([disabled]),
                    input[type="text"]:not([disabled]),
                    input[type="password"]:not([disabled]),
                    input[type="radio"]:not([disabled]),
                    input[type="checkbox"]:not([disabled]),
                    select:not([disabled]),
                    input[type="submit"]:not([disabled]),
                    input[type="reset"]:not([disabled]),
                    div[contenteditable="true"]:not([disabled])`
                );

                const first_element = focusables[0];
                const last_element = focusables[focusables.length - 1];

                setFocusableElements(focusables);
                setFirstElement(first_element);
                setLastElement(last_element);

                return {
                    focusables: focusables,
                    first_element: first_element,
                    last_element: last_element
                };
            }
        }; // end getFocusableElements()

        /**
         * @name handleTabKey
         * @description Handles keyboard navigation in the dialog
         * modal via the Tab key. When tab key is pressed and focus is
         * on the last focusable element, focus will circle around to the
         * first focusable element. If focus is currently on the first
         * focusable element and user presses Shift+Tab, focus will return to
         * the last focusable element.
         * @param {event} e Keyboard event
         * @returns e.preventDefault()
         */
        const handleTabKey = (e) => {
            // console.log(e.key, e.shiftKey);

            const objFocusables = getFocusableElements();
            const { first_element, last_element } = objFocusables;

            // Just tab key
            if (e.key === "Tab" && !e.shiftKey) {
                if (document.activeElement === last_element) {
                    first_element.focus();
                    e.preventDefault();
                }
            }

            // Tab and Shift keys together
            if (e.key === "Tab" && e.shiftKey) {
                if (document.activeElement === first_element) {
                    last_element.focus();
                    e.preventDefault();
                }
            }
        }; // end handleTabKey()

        const objFocusables = getFocusableElements();
        const { focusables } = objFocusables;

        const keyListenersMap = new Map([
            ["Escape", (e) => internalHandler(e)],
            ["Tab", handleTabKey]
        ]);

        function keyListener(e) {
            const listener = keyListenersMap.get(e.key);
            // console.log(listener);
            return listener && listener(e);
        }

        /**
         * Attach event listeners to parent element as
         * well as each focusable child element.
         */
        // Escape key
        parent_element_ref.current.addEventListener("keydown", keyListener);

        focusables.forEach((item) => {
            item.addEventListener("keydown", keyListener);
        });

        // Responsible React clean up
        return () => {
            parent_element_ref.current.removeEventListener("keydown", keyListener);

            focusables.forEach((item) => {
                item.removeEventListener("keydown", keyListener);
            });
        };

  }, [parent_element_ref, handleCloseComponentFx_ref, is_enabled]);

  return {
    parent_element_ref: parent_element_ref,
    focusable_elements: focusable_elements,
    first_element: first_element,
    last_element: last_element
  };

};

useFocusTrap.displayName = "useFocusTrap";
useFocusTrap.propTypes = {
    // parent_element_ref: PropTypes.oneOfType([        
    //     PropTypes.func, 
    //     PropTypes.shape({ current: PropTypes.any })
    // ]).isRequired,
    parent_element: PropTypes.node.isRequired,
    handleCloseComponentFx: PropTypes.func,
};

export default useFocusTrap;
