
import * as React from 'react';

// Material-ui
// import { Button } from '@mui/material';
// import HearingIcon from '@mui/icons-material/Hearing';

// Components
import AudioPlayer from './AudioPlayer';

function HeaderMain() {

    // Project root path
    const path = process.env.SERVER_PATH;

    // const btn_audio_ref = React.useRef(null);
    const audio_player_ref = React.useRef(null);

    const Audio_1984_mp3 = `${path}resources/audio/audio_1984.mp3`;
    // const Audio_1984_m4a = `${path}resources/audio/audio_1984.m4a`;
    // const Audio_1984_webm = `${path}resources/audio/audio_1984.webm`;
    // const Audio_1984_ogg = `${path}resources/audio/audio_1984.ogg`;

    return (
        <>
            <div className="header_main_container">

                <header className="header_main">
                    <div className="bg_image"></div>
                    <div className="title">
                        <h1>
                            How India Executed and Managed the 1984 Genocidal Massacre of Sikhs:
                            &nbsp;A 37 year Illustrated Trail of Tyranny
                        </h1>
                        <h3>by Vishavjit Singh</h3>
                    </div>
                </header>
                
                <AudioPlayer
                    audio_player_ref={audio_player_ref}
                    tracks={
                        [
                            {src: Audio_1984_mp3, type: 'audio/mpeg'}                            
                        ]
                    }
                />
                
            </div>
            
            {/* <div className="audio_btn_container">
                <Button 
                    variant="contained" 
                    startIcon={<HearingIcon />}
                    className="btn_open_audio_control"
                    aria-label="Open audio control"
                    onClick={handleOpenAudioControl} 
                    onKeyDown={handleOnKeyDownAudioControl}
                    ref={btn_audio_ref}
                >
                    Listen To The Story
                </Button>
            </div> */}
            
            <div id="skipToContentAnchor" role="main"></div>
        </>
    );
}

export default HeaderMain;
