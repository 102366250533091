
import React from 'react';

// Components
import ImageZoom from './ImageZoom';


function Conclusion() {

    // Project root path
    const path = process.env.SERVER_PATH;

    const Img1984Is_1984 = `${path}resources/artistImages/1984Is_1984.svg`;

    // Images and image data
    const Img1984Is_1984_data = {
        src: Img1984Is_1984,
        alt: "Large square graphic with blue background and saffron colored text. 1984 Is, is written acroos the top and down the right border are 36 words or short phrases describing what the year could mean.",
        ref: React.useRef(null) 
    };

    return (
        <>            
            <div className="section_anchor" id="Conclusion"></div>
            <div className="section_main_wrapper">
                
                <section className="conclusion">
                    
                    <div className="title">
                        Conclusion
                    </div>

                    <div className="conclusion">

                        <p>
                            There are courageous souls, survivors and activists who have pressed on for some iota of justice to be served at the highest levels of power. A few low level convictions have happened. Some senior members of the Indian government in 1984 have been put on trial despite relentless threats to survivors and lawyers who seek some modicum of justice to be served. One member of the Lower House of parliament from outer Delhi representing the Indian National Congress party was convicted after many trials and mis-trials in December 2018. Sajjan Kumar stands alone among a group of elected officials in India who orchestrated this genocidal massacre to be brought to justice after 37 years.
                        </p> 

                        <figure className="wiesel_quote">
                            <blockquote>
                                <p>
                                    “We must take sides. Neutrality helps the oppressor, never the victim. Silence encourages the tormentor, never the tormented. Sometimes we must interfere. When human lives are endangered, when human dignity is in jeopardy, national borders and sensitivities become irrelevant. Wherever men and women are persecuted because of their race, religion, or political views, that place must - at that moment - become the center of the universe.”
                                </p>
                            </blockquote>

                            <cite>
                                - Elie Wiesel, Holocaust Survivor and 1986 Noble Peace Prize awardee.
                            </cite>
                        </figure>                            

                        <figure className="is_1984">
                            <ImageZoom 
                                img_src={Img1984Is_1984_data.src}
                                img_alt={Img1984Is_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_Img1984Is_1984'
                            />
                            <figcaption>                                    
                                This illustration was created as part of the 2021 Sikhlens Arts & Film Festival calendar                                                                  
                            </figcaption>
                        </figure>

                    </div>

                </section>

            </div>
        </>
    );
}

export default Conclusion;
