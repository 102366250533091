
import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function GlobalFooter() {

    // Project root path
    const path = process.env.SERVER_PATH;

    const LogoICAAD = `${path}resources/general/logo_icaad.svg`;

    const handleOnKeyDown = (e, url) => {

        if (e.key !== undefined) {          
      
          if (e.key === " " || e.key === "Enter") {
            /**
             * Prevent scroll down with spacebar and apparently,
             * Enter key doesn't call toggler() even though the
             * code enteres this block - that is, unless we use
             * e.preventDefault() with Enter key. Hmmm....
             */
            e.preventDefault();
      
            // do link!
            const win = window.open(url, '_blank');
            if (win != null) win.focus();

          }// end inner if

        }// end outer if

    }; // end handleOnKeyDown()

    return (
        <>        
            <footer className="global_footer">
                
                <div className="global_footer_content">

                    <div className="left">

                        <div className="artist_bio">
                            
                            <span className="about_the_author">
                                About the author / artist:
                            </span>

                            <p>                                
                                &nbsp;Vishavjit Singh is a New York City based cartoonist, 
                                TEDx & DEI speaker, performance artist and creator of 
                                <Link 
                                    className="sikhtoons_link"
                                    to={{ pathname: "https://www.sikhtoons.com/"}} 
                                    target="_blank" 
                                    rel="noreferrer noopener">
                                    &nbsp;sikhtoons.com
                                </Link>
                                . Armed with his Captain America persona he travels 
                                across the nation hosting talks & keynotes on identity, 
                                bias, vulnerability & the power of storytelling in schools, 
                                universities, government agencies, companies including 
                                Google, Apple & NASA. He is currently producing 
                                <Link 
                                    className="copyright_text"
                                    to={{ pathname: "https://www.americansikhfilm.com/"}} 
                                    target="_blank" 
                                    rel="noreferrer noopener">
                                    &nbsp;American Sikh
                                </Link>
                                , 
                                a short animation film to be released in 2022. He can be 
                                reached at vsingh at 
                                <Link 
                                    className="sikhtoons_link"
                                    to={{ pathname: "https://www.sikhtoons.com/"}} 
                                    target="_blank" 
                                    rel="noreferrer noopener">
                                    &nbsp;sikhtoons.com
                                </Link>                                
                            </p>
                        </div>

                        <div className="project_by_container">
                            
                            <div className="project_by">

                                <h5>Project by</h5>

                                <Link
                                    className="logo_icaad"
                                    to={{ pathname: "https://icaad.ngo/" }}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img src={LogoICAAD} alt="Site logo" />
                                </Link> 

                                <div className="text_content">
                                    ICAAD works with organizations and communities 
                                    to combat structural and systemic discrimination.
                                    <br />
                                    <Link 
                                        className="learn_more_at_icaad"
                                        to={{ pathname: "http://icaad.ngo/"}} 
                                        target="_blank" 
                                        rel="noreferrer noopener">
                                        Learn more at icaad.ngo
                                    </Link>                               
                                </div>                      

                            </div>
                          
                        </div>

                        <div className="copyright">
                            &copy; { new Date().getFullYear() } 
                            &nbsp;All the illustrations on this page are a copyright of Vishavjit Singh 
                            <Link 
                                className="sikhtoons_link"
                                to={{ pathname: "https://www.sikhtoons.com/"}} 
                                target="_blank" 
                                rel="noreferrer noopener">
                                &nbsp;@sikhtoons.com
                            </Link>
                        </div>

                        <div className="misc_info_container">

                            <div className="terms_and_privacy_links">
                                
                                <Link 
                                    className="terms_of_use_link"
                                    to={{ pathname: "https://icaad.ngo/terms-of-use/"}} 
                                    target="_blank" 
                                    rel="noreferrer noopener">
                                    Terms of Use
                                </Link>
                                &nbsp;<span className="divider">|</span>&nbsp;
                                <Link 
                                    className="privacy_policy_link"
                                    to={{ pathname: "https://icaad.ngo/privacy-policy/"}} 
                                    target="_blank" 
                                    rel="noreferrer noopener">
                                    Privacy Policy
                                </Link>

                            </div>                            

                        </div>

                    </div>

                    <div className="right">

                        <p>
                            Under international law, genocide is defined in the Rome Statute of the International Criminal Court as &#34;acts committed with intent to destroy, in whole or in part, a national, ethnical, racial, or religious group.&#34; Between Oct. 31st - Nov. 4th of 1984, a religious and ethnic group, Sikhs, were targeted for murder and extermination. During this period, identification lists and weapons were given to gangs of assailants by elected officials at public meetings the night before, and slogans calling for the death of all Sikhs echoed throughout the capital city. Moreover, the acts of physical and sexual violence were meant to denigrate and humiliate an entire community creating intergenerational trauma spanning the entire Sikh diaspora. 
                        </p>

                        <p>
                            The Indian government has spent almost four decades obfuscating what happened in 1984 by cloaking the genocidal act under the euphemism of anti-Sikh riots and ensuring impunity for those culpable, including government officials, for the organized targeted killings and rape of Sikhs. Vishavjit&#39;s art provides a first hand account of the state&#39;s action and inaction in the killing of thousands of Sikhs within a 96 hour period.
                        </p>

                        <p>
                            ICAAD&#39;s ARTivism program aims to provide space for artists to elevate the voices of the oppressed. Support the ARTivism program by
                            <Link 
                                to={{ pathname: "https://icaad.ngo/donate"}} 
                                target="_blank" 
                                rel="noreferrer noopener"
                                onKeyDown={(e)=>handleOnKeyDown(e, "https://icaad.ngo/donate")}
                            >                
                                &nbsp;donating here              
                            </Link>
                            .
                        </p>

                    </div>

                </div>

            </footer>
        </>
    )
}

GlobalFooter.displayName = "Global Footer";
// GlobalFooter.propTypes = {}

export default GlobalFooter;
