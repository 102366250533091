
import React from 'react';

// Components
import ImageZoom from './ImageZoom';

function AfterYears() {

    // Project root path
    const path = process.env.SERVER_PATH;

    /**
     * Images are preloaded, so call them using image file path.
     * This will still work if preloading fails.
     */
    const TerrorJournal_1984 = `${path}resources/artistImages/terrorJournal_1984.svg`;
    const TheJoker_1984 = `${path}resources/artistImages/theJoker_1984.png`;
    const IncredibleIndia_1984 = `${path}resources/artistImages/incredibleIndia_1984.svg`;

    // Images and image data
    const TerrorJournal_1984_data = {
        src: TerrorJournal_1984,
        alt: "Cartoon of a newspaper titled, Terror Journal, with the headline, 25 years after 1984 and subtitled, Thousands burnt alive and raped. There are words in the article showing the headlines, Guilty Still Roaming Free and Victims Still Seek Justice.",
        ref: React.useRef(null) 
    };

    const TheJoker_1984_data = {
        src: TheJoker_1984,
        alt: "Cartoon of Sikh Prime Minister on the campaign trail in a clown suit the colors of the Indian national flag, a big red ball for a nose and a button with the colors of the national flag and the words, Vote Congress. The character is saying, We cannot keep 1984 alive forever. It’s time to move on",
        ref: React.useRef(null) 
    };

    const IncredibleIndia_1984_data = {
        src: IncredibleIndia_1984,
        alt: "Indian offical global marketing poster with black background and white text with the words, Incredible India. The I in India is a red exclamation mark. The sub header text is, Plan Your Trip Now.",
        ref: React.useRef(null) 
    };

    return (
        <>
            <div className="section_anchor" id="AfterAllTheseYears"></div>
            <div className="section_main_wrapper">
                
                <section className="after_years">
                        
                    <div className="title">
                        After All These Years
                    </div>

                    <div className="content">

                        <div className="terror_journal">

                            <p>
                                During the 25th anniversary of this pogrom, I cannot help but imagine that even the mastermind perpetrator of 9/11 would be amazed that the saga of this act of terrorism ended with those responsible roaming freely in power, getting promoted, elected in a democracy.
                            </p>

                            <ImageZoom 
                                img_src={TerrorJournal_1984_data.src}
                                img_alt={TerrorJournal_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_TerrorJournal_1984'
                            />

                        </div>

                        <div className="the_joker">

                            <p>
                                On the campaign trail after the 25th anniversary of this genocide, the Sikh Prime Minister of India had a message for Sikhs who talked of justice: Just move on. Justice for him and for many in India about the countless man-made tragedies translates to, “Just is.” We just have to accept these atrocious acts as part of life.
                            </p>

                            <div className="spacer">&nbsp;</div>
                            
                            <ImageZoom 
                                img_src={TheJoker_1984_data.src}
                                img_alt={TheJoker_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_TheJoker_1984'
                                img_zoom_image_modal_class_name='image_modal_TheJoker_1984'
                            />

                        </div>

                        <div className="incredible_india">

                            <p>
                                To borrow from its official global marketing slogan at the time splashed on posters across the globe, bellowed from radio stations and flung out from so many platforms, indeed it is, “Incredible India!” The land known for its rich cultural, religious, musical, linguistic traditions, among others, is also incredible in wanton brutality against its own people.
                            </p>

                            <div className="spacer">&nbsp;</div>
                            
                            <ImageZoom 
                                img_src={IncredibleIndia_1984_data.src}
                                img_alt={IncredibleIndia_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_IncredibleIndia_1984'
                            />                           

                        </div>

                    </div>

                </section>
                
            </div>
        </>
    );
}

export default AfterYears;
