
import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Mui
import { Box, Avatar, Typography } from '@mui/material';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Hooks
import useClickOutside from './../hooks/useClickOutside';
// import useFirstRender from './../hooks/useFirstRender';
import useFocusTrap from '../hooks/useFocusTrap';
// import useEventListener from '../hooks/useEventListener';

// Components
import DisableRightClickAndDragOnImages from './DisableRightClickAndDragOnImages';

function AboutPortal(props) {

    const {
        portal_location_ref,
        focus_on_close_ref,
        is_click,
        is_portal_open,
        // handleOpenPortal,
        handleClosePortal,
        handleOnKeyDownPortal,
    } = props;

    // Project root path
    const path = process.env.SERVER_PATH;

    // AboutPortal component refs
    
    const about_portal_ref = React.useRef(null);
    const btn_close_ref = React.useRef(null);

    // Images
    const SikhCaptAmerica = `${path}resources/artistImages/sikhCaptAmerica.svg`;

    // hrefs for external links
    const NotANovel_href = "http://www.sikhtoons.net/1984-not-a-novel-by-orwell-or-murakami.html";
    const Sikhtoons_href = "https://www.sikhtoons.com/";

    /**
     * @name useClickOutside
     * @description Close dialog when user clicks anywhere 
     * outside the boundaries of it (but only when dialog
     * is open).
     */
    useClickOutside(about_portal_ref.current, () => {
        is_portal_open && handleClosePortal(
            () => setTimeout(() => { focus_on_close_ref.current.focus() }, 50))
    });
   
    /**
     * Changes the overflow property of the HTML body
     * element to 'hidden' to keep the document behind the 
     * portal from scrolling.
     */
     React.useEffect(() => {

        const body_element = document.querySelector("body");

        is_portal_open
            ? body_element.style.overflow = 'hidden'
            : body_element.style.overflow = 'visible';

    },[is_portal_open]);

    /**
     * Adds a class to the document body to use CSS to
     * prevent interaction with the document behind the
     * portal when it is open. In CSS I add 
     * pointer-events: none to the body when it has the
     * class about_portal_active. Also, pointer-events: auto
     * is used in .about_portal_container to keep ability
     * to interact (click, scroll) inside portal.
     */
    React.useEffect(() => {

        is_portal_open && document.body.classList.add('about_portal_active');

        return () => {
            document.body.classList.remove('about_portal_active');
        };

    }, [is_portal_open]);
    
    /*///////////////////////////////
    * FOCUS TRAP FOR ABOUT PORTAL
    ///////////////////////////////*/

    const [parent_element_about_portal, setParentElementAboutPortal] = React.useState(null);
    const [is_enabled_about_portal, setIsEnabledAboutPortal] = React.useState(false);

    React.useEffect(() => {
        setParentElementAboutPortal(portal_location_ref.current);
        // setParentElementAboutPortal(document.querySelector('.about_portal'))
        setIsEnabledAboutPortal(true);
    }, []);
  
    const obj_about_portal = {
        parent_element: parent_element_about_portal,
        handleCloseComponentFx: () => {
          handleClosePortal(
            () => setTimeout(() => { focus_on_close_ref.current.focus() }, 50)
        )},
        is_enabled: is_enabled_about_portal
    }
    
    useFocusTrap(obj_about_portal);
    // const focus_trap_about_portal = useFocusTrap(obj_about_portal);
    // console.log(focus_trap_about_portal);

    /**
     * Accessibility: When hamburger menu opens, set focus
     * to first item.
     */
    React.useEffect(() => {        
        // is_portal_open && focus_trap_about_portal.first_element.focus();
        is_portal_open &&
            setTimeout(() => { btn_close_ref.current.focus() }, 50)
    }, [is_portal_open]);

    /**
     * Prevent ability to bring up context menu and drag images.
     */
    DisableRightClickAndDragOnImages();    

    /**
     * Create React Portal since the dialog does not need
     * to flow in the normal flow of the DOM. The Portal
     * will be placed in image_portal_container_ref element.
     */
    return ReactDOM.createPortal(
        <>
            <div
                className={
                    is_portal_open
                    ? "about_portal_backdrop active" 
                    : "about_portal_backdrop"}
            ></div>
            <div 
                className={
                    is_portal_open 
                    ? "about_portal_container active" 
                    : "about_portal_container"
                }
            >
                <div 
                    ref={about_portal_ref} 
                    id="AboutPortal" 
                    className="about_portal"
                    role="dialog"
                    aria-modal="true"
                    aria-hidden={is_portal_open ? false : true}
                >
                    <header>                        
                        <div className="header_content">

                            <div className="title">
                                About Vishavjit Singh
                            </div>
                            
                            <div className="btn_close_container">
                                <Button 
                                    variant="text"
                                    sx={
                                        is_click 
                                        ? {
                                            '&:focus': {                                        
                                                outline: '2px solid var(--portal-close-button-onclick-outline-color)',
                                                outlineOffset: '-1px',
                                                outlineStyle: 'inset',
                                            }
                                        } : {
                                            '&:focus': {
                                                outline: '2px solid var(--secondary-color)',
                                                outlineOffset: '-1px',
                                                outlineStyle: 'inset',
                                            }
                                        } 
                                    }
                                    startIcon={<CloseIcon />}
                                    className="btn_close"
                                    aria-label="close"
                                    onClick={()=>handleClosePortal(
                                        () => setTimeout(() => { focus_on_close_ref.current.focus() }, 50))} 
                                    onKeyDown={(e) => handleOnKeyDownPortal(
                                        e, false, () => setTimeout(() => { focus_on_close_ref.current.focus() }, 50)
                                    )}
                                    ref={btn_close_ref}
                                >
                                    Close
                                </Button>                                
                            </div>

                        </div>

                    </header>
                    
                    <div className="content">
                        
                        <Box sx={{ p:2 }} className="content_box">

                            <div className="left">

                                <figure className="avatar_group">
                                    <Avatar
                                        className="avatar_captainAmerica"
                                        variant="circular"
                                        sx={{ width: 200, height: 200 }}
                                        alt="Vishavjit Singh as Sikh Captain America"
                                        src={SikhCaptAmerica}
                                    />
                                    <figcaption>
                                        <Typography 
                                            gutterBottom
                                            variant="caption"
                                            className="avatar_caption"
                                        >
                                            Vishavjit Singh as Sikh Captain America
                                        </Typography>
                                    </figcaption>
                                </figure>

                                <Typography 
                                    gutterBottom
                                    sx={{
                                        marginBottom: "1em"
                                    }}
                                >
                                    You can read about my journey surviving this 
                                    carnage in words at the following link:
                                </Typography>

                                <Typography 
                                    gutterBottom
                                    sx={{
                                        marginBottom: "2em"
                                    }}
                                >
                                    <a
                                        href={NotANovel_href}
                                        target="_blank"
                                        rel="noreferrer"                                                                          
                                        // onClick={(e) => handleClickExternalLink(e, {pathname: NotANovel_href})}
                                        // onKeyDown={(e) => handleClickExternalLink(e, {pathname: NotANovel_href})}
                                    >
                                        1984: Not a Novel by Orwell or Murakami
                                    </a>                                
                                </Typography>

                                <Typography 
                                    gutterBottom
                                    className="visit_sikhtoons_text"
                                >
                                    <a
                                        href={Sikhtoons_href}     
                                        target="_blank"
                                        rel="noopener noreferrer"                            
                                        // onClick={(e) => handleClickExternalLink(e, {pathname: Sikhtoons_href})}
                                        // onKeyDown={(e) => handleClickExternalLink(e, {pathname: Sikhtoons_href})}
                                    >
                                        Visit Sikhtoons.com
                                    </a> 
                                    &nbsp; for more of my cartoon works along with writings & cosplay.
                                </Typography>       

                            </div>

                            <div className="right">

                                <Typography
                                    gutterBottom
                                    sx={{
                                        marginBottom: "2em"
                                    }}
                                >
                                    My earthly journey started in Washington DC. Arts came early to me as I doodled figments of my imagination. But thanks to my South Asian heritage I was forced to pick the sciences over arts in middle school. I dropped the pencil for good.
                                </Typography>

                                <Typography
                                    gutterBottom
                                    sx={{
                                        marginBottom: "2em"
                                    }}
                                >
                                    Through college at University of California, Santa Barbara studying biology/anthropology and graduate school at University of California, Berkeley studying Epidemiology/Biostatistics, I fell in love with books. Words became my time machine, my savior and guide. I eventually fell in love with eastern philosophy especially Buddhism and Taoism. That finally led me to the faith of my parents, the Sikh path.
                                </Typography>

                                <Typography
                                    gutterBottom
                                    sx={{
                                        marginBottom: "2em"
                                    }}
                                >
                                    The 9/11 attacks in United States and the subsequent hate crime wave adversely affecting many Sikhs along with many communities created the ripe conditions for an artistic spark. Inspired by the creative response of American editorial cartoonists to the tragic events and one specific cartoon by Pulitzer Prize winning cartoonist Mark Fiore, I started creating turbanful cartoons focusing on Sikh news, happenings and contradictions.
                                </Typography>

                                <Typography gutterBottom>
                                    I create cartoons, journey all over engaging in the art of storytelling, give keynotes, talks, don the uniform of a fictional superhero, design visual products and always open to new forks in the road. I like to create perceptual confusion to open doors for questions, explorations and moments of introspections. Give me a holler to serve your needs.
                                </Typography>                                       

                            </div>

                        </Box>           

                    </div>

                    <footer>
                        <div className="footer_content">

                        </div>
                    </footer>

                </div>
            </div>
        </>,
        portal_location_ref.current
    );
}

AboutPortal.displayName = "AboutPortal";
AboutPortal.propTypes = {
    portal_location_ref: PropTypes.oneOfType([        
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
};

export default AboutPortal;
