
import { createTheme } from "@mui/material";

const theme = createTheme({
    // console.log(props);
    // palette: {
    //     type: props.which_theme,
    // },
    typography: {
        fontFamily: ['Lato', 'sans-serif'].join(',')
    }
});

export default theme;
