
import * as React from "react";

/**
 * @name useClickOutside
 * @description: Runs a function when an event target is not
 * contained within the target.
 * @param {DOM object} target The DOM object to evaluate if click is
 * contained within.
 * @param {function} handler What to do if click is not contained 
 * within the target.
 */
const useClickOutside = (element, handler) => {

    // Maintain reference to target
    const target_ref = React.useRef(element);
    React.useEffect(() => {
        target_ref.current = element;
    }, [element]);

    React.useEffect(() => {

        const listener = (e) => {
            // console.log(target_ref.current, e.target, target_ref.current.contains(e.target));
            // Ignore event if e.target is within the bounds of the DOM element.
            if (target_ref.current && target_ref.current.contains(e.target)) {             
                return;
            }
            handler();
        }
    
        document.addEventListener("click", listener);
    
        return () => {
            document.removeEventListener("click", listener);
        };
    
      }, [target_ref.current, handler])};

export default useClickOutside;
