import React from 'react';
import { PropTypes } from 'prop-types';

// Material-ui
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Hooks
import usePortal from '../hooks/usePortal';
import useFocusTrap from '../hooks/useFocusTrap';
import useClickOutside from '../hooks/useClickOutside';

/**
 * @name ImageZoom
 * @description A component to use for an IMG tag when you want
 * to be able to click on the image and have it display zoomed.
 * @param {any} props Props passed in include the dynamic image
 * data for the image to be zoomed - ie. src and alt.
 * @returns {node} ImageZoom component
 */
function ImageZoom(props) {

    // Destructured props
    const {
        img_src, 
        img_alt, 
        img_zoom_container_main_class_name,
        img_zoom_image_modal_class_name
    } = props;
    
    const user_container_main_class_name = 
        img_zoom_container_main_class_name 
            ? img_zoom_container_main_class_name 
            : '';

    const user_image_modal_class_name = 
        img_zoom_image_modal_class_name 
            ? img_zoom_image_modal_class_name 
            : '';

    // Destructured usePortal methods
    const {
        is_portal_open,
        // handleTogglePortal,
        handleOpenPortal,
        handleClosePortal,
        handleOnKeyDownPortal
    } = usePortal();

    // Project root path
    // const path = process.env.SERVER_PATH;

    // Refs for various parts of the image zoom modal
    const img_zoom_modal_ref = React.useRef(null);
    const img_zoom_modal_content_ref = React.useRef(null);
    const img_zoom_image_modal_ref = React.useRef(null);
    const img_zoom_btn_close_ref = React.useRef(null);
    const img_zoom_container_main_ref = React.useRef(null);
    const img_zoom_image_main_ref = React.useRef(null);

    /*///////////////////////////////
    * FOCUS TRAP 
    ///////////////////////////////*/

    const [parent_element, setParentElement] = React.useState(null);
    const [is_enabled, setIsEnabled] = React.useState(false);

    React.useEffect(() => {
        setParentElement(img_zoom_modal_ref.current);
        setIsEnabled(true);
    }, []);
  
    const obj_about_portal = {
        parent_element: parent_element,
        handleCloseComponentFx: () => {
          handleClosePortal(
            () => setTimeout(() => { img_zoom_container_main_ref.current.focus() }, 50)
        )},
        is_enabled: is_enabled
    }
    
    useFocusTrap(obj_about_portal);
    // const focus_trap_about_portal = useFocusTrap(obj_about_portal);
    // console.log(focus_trap_about_portal);

    /**
     * @name useClickOutside
     * @description Close portal when user clicks anywhere 
     * outside the boundaries of it.
     */
    useClickOutside(img_zoom_modal_content_ref.current, () => {
        is_portal_open && handleClosePortal(
            () => setTimeout(() => { 
                handleZoom(is_portal_open);
                // Only focus target > close with keyboard use.
                // img_zoom_container_main_ref.current.focus();
            }, 50))        
    });// end useClickOutside()

    /**
     * @name handleZoom
     * @description Sets and unsets body styles to keep document
     * behind portal from scrolling and to set pointer-events: none.
     * @param {boolean} state Current state of image zoom portal
     * boolean, is_portal_open
     */
    const handleZoom = ( state, element = null ) => {

        if (!state) {            
            
            if (element !== null) { 
                
                element.animate([                    
                    { opacity: 0 },
                    { opacity: 1 }  
                ], {
                    duration: 300,
                    iterations: 1,
                    fill: "forwards"
                });

            }

            /**
             * Change the overflow property of the HTML body
             * element to 'hidden' to keep the document behind the 
             * element from scrolling.
             */
            document.body.style.overflow = 'hidden'

            /**
             * Add class to BODY tag. In global.css this 
             * is styled with pointer-events: none.
             */
            document.body.classList.add('img_zoom_active');           

        } else {
            // console.log('handleZoom - state: ', state);
            if (element !== null) { 
                
                // element.animate([                    
                //     { opacity: 1 },
                //     { opacity: 0 }  
                // ], {
                //     duration: 300,
                //     iterations: 1,
                //     fill: "forwards"
                // });

            }

            // Return all properties back to defaults
            document.body.style.overflow = 'visible';
            document.body.classList.remove('img_zoom_active');

            // element.style.zIndex = "revert";
            // element.style.transform = `scale3d(1, 1, 1)`;
            // element.animate([
            //     { transform: `scale3d(0, 0, 1)` },               
            // ], {
            //     duration: 300,
            //     iterations: 1,
            //     fill: "forwards"
            // });

        }

    };// end handleZoom()

    /**
     * State to track if image zoom modal was opened via
     * 'click' or 'keydown' actions on the part of the user.
     * This is a simple way to change focus styles on the 
     * modal close button upon whether one or the other 
     * actions was taken. This is to satisfy a style preference
     * for subtler styling than the bolder outlines for 
     * accessibility.
     */
    const [is_click, setIsClick] = React.useState(false);

    return (
        <>
            <div 
                id="imgZoomModal" 
                className={
                    is_portal_open
                    ? "img_zoom_modal"
                    : "img_zoom_modal closed"
                }
                ref={img_zoom_modal_ref}
            >
                <div 
                    className="modal_content"
                    ref={img_zoom_modal_content_ref}
                >
                    <header className="modal_header">

                        <span className="copyright">
                            &copy; { new Date().getFullYear() } 
                            &nbsp;Vishavjit Singh 
                        </span>
                        
                        <Button 
                            disableFocusRipple                                                                        
                            variant="text"
                            sx={
                                is_click 
                                ? {
                                    '&:focus': {
                                        outline: 'none'
                                    },
                                    '&:hover': {                                        
                                        // outline: '1px solid var(--portal-close-button-onclick-outline-color)',
                                        // outlineOffset: '-1px',
                                        // outlineStyle: 'inset',
                                        backgroundColor: 'var(--portal-close-button-onhover-bg-color)',
                                    }
                                } : {
                                    '&:focus': {
                                        outline: '2px solid var(--secondary-color)',
                                        outlineOffset: '-1px',
                                        outlineStyle: 'inset',
                                    }
                                } 
                            }
                            startIcon={<CloseIcon />}
                            className="img_zoom_btn_close"
                            ref={img_zoom_btn_close_ref}
                            aria-label="close"
                            onClick={(e) => {
                                e.stopPropagation();                                
                                handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                                handleClosePortal();
                            }}
                            onKeyDown={
                                (e) => {
                                    /**
                                     * With keyboard use, focus back on trigger when 
                                     * portal is closed.
                                     */
                                    const target = img_zoom_container_main_ref.current;
                                    handleOnKeyDownPortal(
                                        e, false, () => {
                                            handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                                            target.focus();
                                        }
                                    );
                                }
                            }                         
                        >
                            Close
                        </Button>                                

                    </header>
                        
                    <div 
                        className={
                            is_portal_open 
                            ? "modal_body active" 
                            : "modal_body"
                        }                        
                    >
                        <div 
                            className="img_link"
                            role="button"
                            tabIndex="0"                            
                            onClick={(e) => {
                                e.stopPropagation();                                
                                handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                                handleClosePortal();
                            }}
                            onKeyDown={
                                (e) => {
                                    /**
                                     * With keyboard use, focus back on trigger when 
                                     * portal is closed.
                                     */
                                    const target = img_zoom_image_modal_ref.current;
                                    handleOnKeyDownPortal(
                                        e, false, () => {
                                            handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                                            target.focus();
                                        }
                                    );
                                    
                                }
                            }
                        >
                            <img 
                                className={`${user_image_modal_class_name} img_zoom_image_modal`}
                                src={img_src} 
                                alt={img_alt}
                                ref={img_zoom_image_modal_ref}                    
                            />
                        </div>

                    </div>

                </div>
                {/* .modal_content  */}

            </div>

            <div 
                className={
                    is_portal_open
                    ? `${user_container_main_class_name} img_zoom_container_main active`
                    : `${user_container_main_class_name} img_zoom_container_main`
                }
                role="button"
                tabIndex="0"                
                ref={img_zoom_container_main_ref}
                onClick={(e) => {
                    e.stopPropagation();
                    handleOpenPortal(e, () => {
                        setIsClick(true);
                        setTimeout(() => { img_zoom_btn_close_ref.current.focus() }, 50);
                    });
                    handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                }}
                onKeyDown={
                    (e) => {                        
                        /**
                         * Open image zoom when Space|Enter keys pressed and then
                         * run callbackFxs handleZoom() and setting focus to close
                         * button. A short delay is needed b/c the image zoom modal
                         * is a child of img_zoom_backdrop, and img_zoom_backdrop 
                         * begins display: none. Gotta wait for display: block to
                         * occur so the close button can be recognized in the DOM
                         * and get focus.
                         */
                        handleOnKeyDownPortal(
                            e, true, () => {
                                handleZoom(is_portal_open, img_zoom_modal_content_ref.current);
                                setIsClick(false);
                                setTimeout(() => { img_zoom_btn_close_ref.current.focus() }, 50);
                            }
                        );
                    }
                }                
            >
                <img 
                    className="img_zoom_image_main" 
                    src={img_src} 
                    alt={img_alt}
                    ref={img_zoom_image_main_ref}
                />
            </div>
        </>
    );
}

ImageZoom.displayName = "ImageZoom";
ImageZoom.propTypes = {
    img_src: PropTypes.string.isRequired,
    img_alt: PropTypes.string.isRequired,
    img_zoom_container_main_class_name: PropTypes.string,
    img_zoom_image_modal_class_name: PropTypes.string,
};

export default ImageZoom;
