
import React from 'react';

// Components
import ImageZoom from './ImageZoom';

function Massacre() {

    // Project root path
    const path = process.env.SERVER_PATH;

    /**
     * Images are preloaded, so call them using image file path.
     * This will still work if preloading fails.
     */
    const TotalBurntAlive_1984 = `${path}resources/artistImages/totalBurntAlive_1984.svg`;    
    const BurntSadars_1984 = `${path}resources/artistImages/burntSadars_1984.svg`;

    const TotalBurntAlive_1984_data = {
        src: TotalBurntAlive_1984,
        alt: "A cartoon box of matches with the text, Thousands Burnt Alive",
        ref: React.useRef(null) 
    };

    const BurntSadars_1984_data = {
        src: BurntSadars_1984,
        alt: "Cartoon black and white drawing of person in white on a black background with a sign held over their genitals reading, Before we burnt Sardars we relieved ourselves on their wives in front of their children",
        ref: React.useRef(null) 
    };

    return (
        <>            
            <div className="section_anchor" id="Massacre"></div>
            <div className="section_main_wrapper">
                
                <section className="massacre">                   

                    <div className="title">
                        Massacre
                    </div>

                    <div className="content">

                        <div className="total_burnt_alive">

                            <p>
                                Sikh men and boys in the thousands were burnt alive by having kerosene, petrol or rubber tires forced upon their bodies and then with the flick of a matchstick, they were lit on fire. Everyone in the halls of power, media and the intelligentsia decided to control this narrative by focusing only on the killings in the capital city, ignoring the countless slaughtered across the nation. The number of those killed will never be known. Life in a nation of over a billion is cheap.  
                            </p>

                            <div className="spacer">&nbsp;</div>
                            
                            <ImageZoom 
                                img_src={TotalBurntAlive_1984_data.src}
                                img_alt={TotalBurntAlive_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_TotalBurntAlive_1984'
                            />
                            
                        </div>

                        <div className="pillage">

                            <p>
                                Burning Sikh men alive was not enough to satisfy the wolfish hunger for ghoulish revenge. Wives and daughters were raped by men young and old, many times over, before husbands were set on fire in the audience of their children. The twisted goal was to scar the collective memory of those who survived.
                            </p>

                            <ImageZoom 
                                img_src={BurntSadars_1984_data.src}
                                img_alt={BurntSadars_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_BurntSadars_1984'
                            />                           

                        </div>

                    </div>
                    
                </section>                
                
            </div>
        </>
    );
}

export default Massacre;
