
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

// Components
import RippleButton from './RippleButton';

// Hooks
import useToggler from '../hooks/useToggler';

// Icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function BackToTopFab() {

    // Project root path
    const path = process.env.SERVER_PATH;

    // Browser history object
    const history = useHistory();

    const [
        toggle_state, 
        handleToggler, 
        // handleOpenToggler, 
        // handleCloseToggler
    ] = useToggler();

    const [scroll_position_y, setScrollPositionY] = React.useState(0);

    const doStuff = () => {

        // Method from react-scroll pkg
        animateScroll.scrollToTop({
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuint',
        });

        // Reset focus to root of document (HTML element)
        const root = document.documentElement;
        root.tabIndex = 0;
        root.focus();

        // Update address bar to / by removing location hashes
        // from anchor links (ie. #TheSetup)
        handleHistoryPush(`${path}`);

    };// end doStuff()

    const handleScrollToTop = (e) => {

        /**
         * Prevent Tab key from initiating back to top action.
         * Only space bar or Enter key can be used.
         */
        if (e.key !== undefined && e.type === 'keydown') {
            if (e.key === " " || e.key === "Enter") {
                // Prevent scroll down with spacebar.
                e.preventDefault();
                doStuff();
            }

        } else {// ie. 'click'
            
            doStuff();

        }

    };// end handleScrollToTop()

    React.useEffect(() => {
        // const flag = (scroll_position_y > 300) ? true : false;
        (scroll_position_y > 300) ? handleToggler(true) : handleToggler(false);
    },[scroll_position_y]);

    React.useEffect(() => {
        
        const scrollFx = () => {
            // Get the current scroll Y value
            let scroll_pos_y = window.scrollY;
            setScrollPositionY(scroll_pos_y);
        }        

        // passive: true so browser doesn't wait before scrolling
        window.addEventListener('scroll', scrollFx, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', scrollFx, { passive: true });
        };

    }, []);

    /**
     * @name handleHistoryPush
     * Push new path (with optional state) onto history cue. This allows for
     * expected behavior of browser back/forward buttons.
     * @param {string} str_path path to add to history cue
     * @param {object} state Optional. Any values to carry to next history state.
     */
    function handleHistoryPush(str_path, state = {}) {
        history.push(str_path, state);
    }// end handleHistoryPush()

    return (
        <>
            <RippleButton 
                className={toggle_state ? "back_to_top_fab show" : "back_to_top_fab"}
                tabIndex="0"                
                aria-label="Back to Top"
                onClick={handleScrollToTop}
                onKeyDown={handleScrollToTop} 
            >
                <KeyboardArrowUpIcon />
            </RippleButton>
        </>
    )

}

export default BackToTopFab;
