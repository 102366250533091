import React from 'react';
/**
 * There is an issue in React with anchor tags. 
 * react-router-hash-link enables scrolling to #hash-fragments 
 * when using the <HashLink> component to navigate.
 */
import { HashLink } from 'react-router-hash-link';

function SkipToContentAnchor() {    

    /**
     * Explicitly set focus to the skip anchor tag 
     * (#skipToContentAnchor). In order to give the anchor
     * focus, it needs to be tabbable (tabindex="-1" allows
     * the element to receive focus, as well as keeping the 
     * element from ending up being an invisible, tabbable 
     * element).
     * 
     * Note: Chrome seems to be fine with just the HashLink, but
     * Safari needs the setAreaFocus() method code block. 
     */
    const setAreaFocus = (e) => {

        if (e.key === "Enter") {
            
            const skip_to_content_anchor = document.querySelector('#skipToContentAnchor');
            // const skip_anchor_scroll_top = skip_to_content_anchor.scrollTop;
            // window.scrollTo(0, skip_anchor_scroll_top);
            skip_to_content_anchor.setAttribute("tabindex" , "-1");
            skip_to_content_anchor.focus();
            // skip_to_content_anchor.removeAttribute("tabindex");
        }

    };// end setAreaFocus()
    
    return (
        <HashLink smooth to="#skipToContentAnchor"
                  id="skipper"
                  className="skip_to_content_link"
                  role="button"                  
                  onClick={setAreaFocus}                  
                  >
            Skip to main content
        </HashLink>
    )
}

export default SkipToContentAnchor;
