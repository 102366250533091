
import React from 'react';

// Components
import ImageZoom from './ImageZoom';

function Setup() {

    // Project root path
    const path = process.env.SERVER_PATH;

    /**
     * Images for this component
     */
    const FuneralPreparations_1984 = `${path}resources/artistImages/funeralPreparations_1984.svg`;
    const MourningChants_1984 = `${path}resources/artistImages/mourningChants_1984.svg`;
    const OrwellianRecipe_1984 = `${path}resources/artistImages/orwellianRecipe_1984.svg`;
    const ToCallTheArmy_1984 = `${path}resources/artistImages/toCallTheArmy_1984.svg`;
    const PoliceComplicity_1984 = `${path}resources/artistImages/policeComplicity_1984.svg`;

    // Images and image data
    const FuneralPreparations_1984_data = {
        src: FuneralPreparations_1984,
        alt: "Cartoon drawing of outside the door to Delhi Congress Party Headquaters with sounds from inside the room beyond of macabe ideas about how to prepare to kill people",
        ref: React.useRef(null) 
    };

    const MourningChants_1984_data = {
        src: MourningChants_1984,
        alt: "Drawing of India with the chant, Khoon ka badla khoon se ledge, with blood we shall avenge blood",
        ref: React.useRef(null) 
    };

    const OrwellianRecipe_1984_data = {
        src: OrwellianRecipe_1984,
        alt: "Curry recipe label but instead of food ingredients, methods to kill Sikhs are listed",
        ref: React.useRef(null) 
    };

    const ToCallTheArmy_1984_data = {
        src: ToCallTheArmy_1984,
        alt: "Cartoon about how officials delayed calling in the army until several days after the carnage",
        ref: React.useRef(null) 
    };

    const PoliceComplicity_1984_data = {
        src: PoliceComplicity_1984,
        alt: "Cartoon showing how police were complicit with the mobs",
        ref: React.useRef(null) 
    };
    
    return (
        <>            
            <div className="section_anchor" id="TheSetup"></div>
            <div className="section_main_wrapper">
                
                <section className="setup">                   

                    <div className="title">
                        The Setup
                    </div>                        

                    <div className="content">
                        
                        <div className="funeral_preparations">

                            <div className="text">

                                <p>
                                    On October 31st, 1984 the Prime Minister of India, Indira Gandhi, was assassinated by her two Sikh bodyguards.
                                </p>

                                <p>
                                    Prior to her assassination, there were years of political conflict in the north Indian state of Punjab. To silence dissidents willing to take up arms against the state because of human rights abuses, Mrs. Gandhi had ordered army troops to storm the holiest of Sikh Gurdwaras (house of worship), Darbar Sahib, in the city of Amritsar, in June when thousands of Sikh pilgrims were visiting Darbar Sahib to commemorate the martyrdom of Guru Arjan Dev Ji. The state coordinated its attack precisely on this date to maximize the pain it could inflict on the Sikh community. The army targeted dissidents and disregarded the life of those pilgrims who came to worship. Sikhs globally were stunned by the scope and planning of this well-coordinated attack.
                                </p>

                                <p>
                                    The staggering number of murders in the last months of her life, however, would not be the last of Mrs. Gandhi’s legacy. Immediately after her death, preparations began for one of the most organized massacres of the late 20th century.
                                </p>

                                <p>
                                    I was fortunate enough, along with my family, to survive.
                                </p>

                            </div>
                            
                            <figure className="img_funeralPreparations_1984">
                                <ImageZoom 
                                    img_src={FuneralPreparations_1984_data.src}
                                    img_alt={FuneralPreparations_1984_data.alt}
                                    img_zoom_container_main_class_name='container_main_FuneralPreparations_1984'
                                />
                                <figcaption>
                                    In the immediate hours after the assassination of the Prime Minister, funeral preparations began not for one body, but for many, whose number would be determined by the whims of revenge seekers.
                                </figcaption>
                            </figure>                            

                        </div>

                        <div className="mourning_chants">

                            <ImageZoom 
                                img_src={MourningChants_1984_data.src}
                                img_alt={MourningChants_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_MourningChants_1984'
                            />

                            <p>
                                In those days, there was only one television station, and it was controlled by the government. The nation mourned with the broadcast images of countless people streaming past the body of the slain leader with non-stop shouts in the background, “Khoon ka badla khoon se ledge” (“With blood we shall avenge blood”). The entire nation absorbed this televised message from political leadership, influential citizens and Bollywood icon, Amitabh Bachchan.
                            </p>

                        </div>

                        <div className="orwellian_recipe">

                            <p className="orwellian_recipe_text">
                                The calls for blood were part of an Indian version of an Orwellian curry — the mighty powers that be, with all of their big brother machinery, were preparing for a roast of a very unique kind.
                            </p>

                            <ImageZoom 
                                img_src={OrwellianRecipe_1984_data.src}
                                img_alt={OrwellianRecipe_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_OrwellianRecipe_1984'
                            />
                            
                        </div>
                        
                        <div className="call_army">                            

                            <p className="call_army_text">
                                While Sikhs were being hunted and killed, the political and security leadership had a long conversation about when to call in the army to put an end to this organized game of vengeance.
                            </p>

                            <div className="spacer">&nbsp;</div>

                            <ImageZoom 
                                img_src={ToCallTheArmy_1984_data.src}
                                img_alt={ToCallTheArmy_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_ToCallTheArmy_1984'
                            />                       

                        </div>

                        <div className="police_complicity">                            

                            <p>
                                So, what were the cops doing while mobs in cities across the northern half of India went on the killing spree? They joined in, guided the mobs and provided security to the killers. I saw this with my own eyes. Police officers managed a throng of hundreds of men bent upon revenge. That was one of the most insidious ongoing aspects of the carnage.
                            </p>

                            <ImageZoom 
                                img_src={PoliceComplicity_1984_data.src}
                                img_alt={PoliceComplicity_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_PoliceComplicity_1984'
                            />

                        </div>

                    </div>

                </section>

            </div>
        </>
    );
}

export default Setup;
