import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const SERVER_PATH = process.env.SERVER_PATH;

function NoMatch() {
  let location = useLocation();
  return (
    <>
      <Helmet>
        <title>1984 Page Not Found</title>
      </Helmet>
      <div className="container_centered no_match_page">
                
        <header className="page_header">
            <h1 className="page_title">Page Not Found</h1>            
        </header>

        <p className="message">
          You have requested a page that was not found: <code>{location.pathname}</code>
        </p>
        
        <p className="return_to_home_link">
          <Link to={`${SERVER_PATH}`}>
            Return to home page
          </Link> 
        </p>

      </div>
    </>
  );
}

export default NoMatch;
