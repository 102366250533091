
import * as React from 'react';
import PropTypes from 'prop-types';

function usePortal(initial_value = false) {

    const [is_portal_open, setIsPortalOpen] = React.useState(initial_value);

    const handleTogglePortal = React.useCallback((new_state = null) => {
        // console.log('usePortal - handleTogglePortal (new_state): ', new_state);
        
        // Imperative setting of value
        if (new_state === false) setIsPortalOpen(false);
        if (new_state === true ) setIsPortalOpen(true);

        // Default is to simply toggle value
        new_state === null && setIsPortalOpen(!is_portal_open);
        
    }, [is_portal_open]);

    /**
     * @name handleClosePortal
     * @description sets state for portal to false (closed).
     * @param {function} callbackFx [optional] Function to call after
     * portal is closed.
     */
    const handleClosePortal = (callbackFx = null) => {
        setIsPortalOpen(false);
        
        /**
         * Run callback function, if given (ie. reset focus
         * to trigger button, etc.).
         */
        if (callbackFx !== null) callbackFx();            
    };

    /**
     * @name handleOpenPortal
     * @description Handler method to set is_portal_open 
     * state variable to true (to open portal). 
     */
    const handleOpenPortal = (e, callbackFx = null) => {       
        e.preventDefault();
        
        setIsPortalOpen(true);

        /**
         * Run callback function, if given (ie. set focus
         * to dialog close button, etc.).
         */
         if (callbackFx !== null) callbackFx();

    };// end handleOpenPortal()

    /**
     * @name handleOnKeyDownPortal
     * @description 
     * @param {event} e
     * @param {boolean} bValue true (open portal) | false (close portal)
     * @param {function} callbackFx [optional] Function to call after
     * portal is closed.
     */
    const handleOnKeyDownPortal = (e, bValue, callbackFx = null) => {

        if (e.key !== undefined) {

            if (e.key === " " || e.key === "Enter") {
                
                e.preventDefault();

                bValue 
                    ? handleOpenPortal(e, callbackFx)
                    : handleClosePortal(callbackFx);                        

            } else if (e.key === "Escape") {

                handleClosePortal(callbackFx);

            }
        }

    };// end handleOnKeyDownPortal()

    return {
        is_portal_open: is_portal_open, 
        handleTogglePortal: handleTogglePortal,
        handleOpenPortal: handleOpenPortal,
        handleClosePortal: handleClosePortal,
        handleOnKeyDownPortal: handleOnKeyDownPortal
    };
}

usePortal.displayName = "usePortal";
usePortal.propTypes = {
    initial_value: PropTypes.bool,    
}

export default usePortal;
