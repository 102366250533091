
import React from 'react';
import PropTypes from 'prop-types';

const RippleButton = ({ children, ...props }) => {
    function createRipple(event) {
      const button = event.currentTarget;
  
      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
  
      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
      circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
      circle.classList.add("ripple");
  
      const ripple = button.getElementsByClassName("ripple")[0];
  
      if (ripple) {
        ripple.remove();
      }
  
      button.appendChild(circle);
    }

    return  (
      <>
        <div className="back_to_top_fab_container" tabIndex="-1">

          <button
              className={props.className}                
              tabIndex="0"                
              aria-label="Back to Top"
              onClick={(e) => {
                  e.preventDefault();
                  createRipple(e);                                   
                  props.onClick(e);
              }}
              onKeyDown={props.onKeyDown}
          >
              {children}
          </button>

        </div>
      </>
    )
};

RippleButton.displayName = "GlobalNavbar";
RippleButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    children: PropTypes.node,
}
export default RippleButton;
