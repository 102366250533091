/**
 * @name ScrollToTopAndFocus.js
 * @file Emulates what occurs with sites that have a page refresh on 
 * each new page visited: scrolling to the top of the new page and resetting 
 * focus to that document.
 * @author Darren Brooks
 * @copyright 2020 Darren Brooks
 * 
 */
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function ScrollToTopAndFocus() {
    const {pathname} = useLocation();

    // Always scroll to top of page, even if on that page. 
    window.scrollTo(0, 0);

    /**
     * When the location pathname changes, the useEffect is fired.
     * Focus() will be set to the root document element (ie <html>).
     * This is helpful for accessing the Skip to Content accessibility
     * link. 
     */
    useEffect(() => {
        // console.log('ScrollToTop');        

        // Reset focus to root of document (HTML element)
        const root = document.documentElement;
        root.tabIndex = 0;
        root.focus();

    }, [pathname]);

    return null;
}

export default ScrollToTopAndFocus;
