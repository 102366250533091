/**
 * @name index.js
 * @author Darren Brooks
 * @copyright 2020 Darren Brooks
 * @file A few things to note about the setup for index.js:  
 * - ScrollToTopAndFocus is a component which emulates 
 * non-SPAs by bringing focus to top of a newly navigated-to
 * page. 
 * - SkipToContentAnchor adds accessiblity to each page by
 * allowing users to tab and skip the global navbar navigation
 * links and jump into the page content area.
 * - CSS stylesheets are loaded here in this file to allow global
 * access of css. Order of css imports: more general first 
 * (ie. global.css), then more specific (ie. home.css).
 * 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

// Main app
import App from './app';

// Components
import ScrollToTopAndFocus from './components/ScrollToTopAndFocus';
import SkipToContentAnchor from './components/SkipToContentAnchor';

// Styles
// import 'bulma/css/bulma.css';
import './styles/global.css';
import './styles/home.css';

ReactDOM.render(
    <React.StrictMode>          
        <Router>
            <SkipToContentAnchor />
            <ScrollToTopAndFocus />
            <App />            
        </Router>       
    </React.StrictMode>,
    document.getElementById("root")
);
