
import React from 'react';

// Components
import ImageZoom from './ImageZoom';

function Aftermath() {

    // Project root path
    const path = process.env.SERVER_PATH;

    /**
     * Images are preloaded, so call them using image file path.
     * This will still work if preloading fails.
     */
    const WhyCallItRiot_1984 = `${path}resources/artistImages/whyCallItRiot_1984.svg`;
    const ZailSingh_1984 = `${path}resources/artistImages/zailSingh_1984.jpg`;
    const WhenABigTreeFalls_1984 = `${path}resources/artistImages/whenABigTreeFalls_1984.png`;
    const RajivThanksMob_1984 = `${path}resources/artistImages/rajivThanksMob_1984.png`;
    const LandslideWin_1984 = `${path}resources/artistImages/landslideWin_1984.svg`;
    const ConceivingInjustice_1984 = `${path}resources/artistImages/conceivingInjustice_1984.png`;
    const TheApology_1984 = `${path}resources/artistImages/theApology_1984.png`;

    // Images and image data
    const WhyCallItRiot_1984_data = {
        src: WhyCallItRiot_1984,
        alt: "Cartoon showing Indian media choosing to define the carnage as a riot",
        ref: React.useRef(null) 
    };

    const ZailSingh_1984_data = {
        src: ZailSingh_1984,
        alt: "Cartoon of Indian national flag in background and in the foreground showing the head of the Indian president in a hand with palm turned up, saying the words, I do not have the powers to intervene.",
        ref: React.useRef(null) 
    };

    const WhenABigTreeFalls_1984_data = {
        src: WhenABigTreeFalls_1984,
        alt: "Cartoon of the words, When a big tree falls the earth shakes a little - words used by Prime Minister of India to explain the root cause of the butchery",
        ref: React.useRef(null) 
    };

    const RajivThanksMob_1984_data = {
        src: RajivThanksMob_1984,
        alt: "Cartoon of Prime Minister of India thanking the masses that engaged in mass murder with the words, From the way you put a stop to it, from the way India has again been brought back to the path of unity with your help, and is able to stand united together again, the world can see that India has become a genuine democracy",
        ref: React.useRef(null) 
    };
    
    const LandslideWin_1984_data = {
        src: LandslideWin_1984,
        alt: "Cartoon of newspaper with the name, Democrazy, and the headline, Landslide election win for congress, and the sub headings, Sympathy vote for Gandhi, and accused ministers back in power. Below the sub headers is the graphic of a hand ‘giving the finger’ to the Indian national flag.",
        ref: React.useRef(null) 
    };
    
    const ConceivingInjustice_1984_data = {
        src: ConceivingInjustice_1984,
        alt: "Cartoon with a series of frames showing how various committees were formed but no justice was ever served. Each frame shows a woman with a large belly as if pregnant with the name of various committees written across the abdomen. The woman and her belly are colored like the Indian national flag.",
        ref: React.useRef(null) 
    };
    
    const TheApology_1984_data = {
        src: TheApology_1984,
        alt: "A cartoon showing those officials with blood on their hands standing behind the Sikh Prime Minister and laughing at getting a Sikh man to apologize for the massacre of thousands of Sikhs at the hands of his own political party. The cartoon is titled, The Apology, and displays the words, I have no hesitation in apologizing not only to the Sikh community but the whole nation because what took place in 1984 is the negation of the concept of nationhood and what’s enshrined in our constitution.",
        ref: React.useRef(null) 
    };

    
    return (
        <>            
            <div className="section_anchor" id="TheAftermath"></div> 
            <div className="section_main_wrapper">
                
                <section className="aftermath">                   

                    <div className="title">
                        The Aftermath
                    </div>

                    <div className="content">

                        <div className="why_riot">

                            <p>
                                From the very first reporting of the massacre, the words used to define it would scar, distort, and pillage the truth: The term “riot” was used as a euphemism to explain the instinctive response of men grieving their so-called “mother of the nation.” That simple four-letter word would launch the ultimate strategy in absolving the real Machiavellian powers behind the organized carnage, terrorism and genocide.
                            </p>

                            <ImageZoom 
                                img_src={WhyCallItRiot_1984_data.src}
                                img_alt={WhyCallItRiot_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_WhyCallItRiot_1984'
                            />

                        </div>

                        <div className="intervene">

                            <ImageZoom 
                                img_src={ZailSingh_1984_data.src}
                                img_alt={ZailSingh_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_ZailSingh_1984'
                            />

                            <p>
                                India’s president, a powerless nominal head of state, was a Sikh man who had been hand-picked by the slain Prime Minister. His only response to this madness was to announce his powerlessness. For those unfamiliar with the Indian political system, the hand is an official logo of the Indian Congress Party, which was in power at the time.
                            </p>

                        </div>

                        <div className="big_tree">

                            <ImageZoom 
                                img_src={WhenABigTreeFalls_1984_data.src}
                                img_alt={WhenABigTreeFalls_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_WhenABigTreeFalls_1984'
                            />                           

                            <p>
                                The newly anointed Prime Minister of India, the son of the slain leader, would address the nation a few days after the bloodbath and the funeral. He used the famous words above to literally explain the root cause of the butchery. For him and the country, the genocidal massacre was nothing more than the law of physics in motion.
                            </p>

                        </div>

                        <div className="thanks">

                            <p>
                                As if that quote were not enough, the new Prime Minister went on to thank the masses that engaged in the mass murder for their benevolence and discipline to bring things back to normal. Those were his actual words.
                            </p>                        

                            <div className="spacer">&nbsp;</div>

                            <ImageZoom 
                                img_src={RajivThanksMob_1984_data.src}
                                img_alt={RajivThanksMob_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_RajivThanksMob_1984'
                            />

                        </div>
                        
                        <div className="landslide">

                            <p>
                                Just over a month later, the people of India went to the polls and handed one of the biggest landslide victories to the ruling party—the party that had orchestrated the bloodletting now found itself with an even bigger mandate to rule.
                            </p>

                            <div className="spacer">&nbsp;</div>

                            <ImageZoom 
                                img_src={LandslideWin_1984_data.src}
                                img_alt={LandslideWin_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_LandslideWin_1984'
                            />

                        </div>

                        <div className="conceiving_injustice">

                            <p>
                                The new government and all successive governments that came to power over the next 20 years engaged in one of the most ingenious and farcical judicial tricks: powerless commissions composed of prominent citizens were asked repeatedly to present investigative findings about the culprits behind the Anti-Sikh massacre. The commissions were manipulated any time they came close to the truth by factually pointing fingers back at those in power.
                            </p>

                            <div className="spacer">&nbsp;</div>

                            <ImageZoom 
                                img_src={ConceivingInjustice_1984_data.src}
                                img_alt={ConceivingInjustice_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_ConceivingInjustice_1984'
                            />

                        </div>

                        <div className="the_apology">

                            <p>
                                Call it a comedy of ironies. The 10th commission, twenty years after the carnage, implicated the political leadership of the ruling Congress Party. Its selected Prime Minister at the time was a Sikh man, Manmohan Singh. Blue-turbaned and mild-mannered, he could offer no hope for justice. So instead, he offered an apology. Those with blood on their hands stood behind him laughing at the ultimate Sikh joke: getting a Sikh man to apologize for the massacre of thousands of Sikhs at the hands of his own political party.
                            </p>

                            <div className="spacer">&nbsp;</div>
                            
                            <ImageZoom 
                                img_src={TheApology_1984_data.src}
                                img_alt={TheApology_1984_data.alt}
                                img_zoom_container_main_class_name='container_main_TheApology_1984'
                            />                            

                        </div>

                    </div>

                </section>

            </div>
        </>
    );
}

export default Aftermath;
