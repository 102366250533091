import React from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import axios from "axios";

// Pages
import Home from "./pages/Home";
import NoMatch from "./pages/NoMatch";

// Components
import GlobalNavbar from "./components/GlobalNavbar";
import GlobalFooter from "./components/GlobalFooter";

// MUI
import { Paper } from "@mui/material";

// Contexts
import { MuiThemeContextProvider } from './contexts/MuiThemeContext';

// Errors
import ErrorBoundary from './components/ErrorBoundary';

function App() {

  // Project root path
  const path = process.env.SERVER_PATH;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>1984</title>
          <meta charset="UTF-8" />
          <meta name="keywords" content="I Survived a Genocideal Massacre 30 years Ago: A Cartoon Biography by Vishavjit Singh" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="I Survived a Genocideal Massacre 30 years Ago: A Cartoon Biography by Vishavjit Singh" />
          <meta name="theme-color" content="#F2F2F2" />
          <meta name="robots" content="index,follow" />
          <meta name="googlebot" content="index,follow" />
          <meta name="subject" content="I Survived a Genocideal Massacre 30 years Ago: A Cartoon Biography by Vishavjit Singh" />
          <link rel="manifest" href={`${path}manifest.json`} />
          <link rel="apple-touch-icon" href={`${path}apple-touch-icon.png`} />
          <link rel="mask-icon" href={`${path}pinned.svg`} color="#000" />
        </Helmet> 
        <MuiThemeContextProvider>          
          <Paper id="globalPaper" className="global_paper">             
            <ErrorBoundary>
              <GlobalNavbar />
              <Switch>
                <Route exact path={path} component={Home} />          
                <Route component={NoMatch} />
              </Switch>
              <GlobalFooter />
            </ErrorBoundary>
          </Paper>
        </MuiThemeContextProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
