
import * as React from 'react';
import PropTypes from 'prop-types';

// Material-ui
// import { IconButton } from '@mui/material';
import HearingIcon from '@mui/icons-material/Hearing';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';


const PlayButton = () => {
    return <PlayArrowIcon fontSize="large" className="play_button" />
};
const PauseButton = () => {
    return <PauseIcon fontSize="large" />
};
const ForwardButton = () => {
    return <FastForwardIcon fontSize="medium" />
};
const RewindButton = () => {
    return <FastRewindIcon fontSize="medium" />
};
// const VolumeButton = () => {
//     return <VolumeUpIcon fontSize="large" />
// };
// const VolumeMuteButton = () => {    
//     return <VolumeOffIcon fontSize="large" />
// };

/**
 * @name AudioPlayer
 * @description A React audio player
 * @param {object} tracks 
 * @returns AudioPlayer component
 */
const AudioPlayer = (props) => {

    // Project root path
    // const path = process.env.SERVER_PATH;

    const {
        audio_player_ref, tracks
    } = props;    

    /* 
        <source src={track_mp4} type="audio/mp4"/>
        <source src={track_webm} type="audio/webm"/>
        <source src={track_ogg} type="audio/ogg"/> 
    */
    const TrackSources = tracks.map((track, i) => {
        return <source key={i} src={track.src} type={track.type} />        
    });
    // console.log(TrackSources);

    const progress_bar_ref = React.useRef(null);
    const animation_ref = React.useRef(null);

    const [is_playing, setIsPlaying] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);
    // const [speed, setSpeed] = React.useState(1);

    const onLoadedMetadata = () => {
        const seconds = Math.floor(audio_player_ref.current.duration);
        setDuration(seconds);
        progress_bar_ref.current.max = seconds;
    };// end onLoadedMetadata()

    const updateCurrentTime = () => {
        setCurrentTime(progress_bar_ref.current.value);
    };

    const pause = () => {
        audio_player_ref.current.pause();
        cancelAnimationFrame(animation_ref.current);
        setIsPlaying(false);
    };// end pause()

    const play = () => {
        audio_player_ref.current.play();
        animation_ref.current = requestAnimationFrame(whilePlaying);
        setIsPlaying(true);
    };// end play()

    const restart = () => {
        progress_bar_ref.current.value = 0;
        updateCurrentTime();
        
        pause();
        // togglePlayPause();
    };// end restart()

    // const changePlaybackSpeed = () => {
    //     switch (speed) {
    //       case 1:
    //         audio_player_ref.current.playbackRate = 1.2;
    //         setSpeed(1.2);
    //         break;
    //       case 1.2:
    //         audio_player_ref.current.playbackRate = 1.5;
    //         setSpeed(1.5);
    //         break;
    //       case 1.5:
    //         audio_player_ref.current.playbackRate = 1.7;
    //         setSpeed(1.7);
    //         break;
    //       case 1.7:
    //         audio_player_ref.current.playbackRate = 2;
    //         setSpeed(2);
    //         break;
    //       case 2:
    //       default:
    //         audio_player_ref.current.playbackRate = 1;
    //         setSpeed(1);
    //         break;
    //     }
    // };

    /**
     * @name whilePlaying
     * @description Every frame the progress bar moves, update the current time
     * @returns
     */
    const whilePlaying = () => {
        progress_bar_ref.current.value = Math.floor(audio_player_ref.current.currentTime);
        progress_bar_ref.current.style.setProperty(
            '--seek-before-width',
            `${(progress_bar_ref.current.value / duration) * 100}%`
        );
        updateCurrentTime();

        /**
         * When we reach the end of the audio track have progress bar jump
         * back to the start, and show play button again.
         */        
        if (parseInt(progress_bar_ref.current.value) === parseInt(duration)) {                   
            restart();
            return;
        }

        /**
         * Better than useEffect() to handle repainting in reliable time frame.
         */        
        animation_ref.current = requestAnimationFrame(whilePlaying);
    };// end whilePlaying()

    /**
     * @name calculateTime
     * @description 
     * @param {integer} secs 
     * @returns 
     */
    const calculateTime = (secs) => {
        
        const minutes = Math.floor(secs / 60);
        const returned_minutes = 
            (minutes < 10) ? `0${minutes}` : `${minutes}`;
        
        const seconds = Math.floor(secs % 60);
        const returned_seconds =
            (seconds < 10) ? `0${seconds}` : `${seconds}`;
        
        return `${returned_minutes}:${returned_seconds}`;

    };// end calculateTime()

    /**
     * @name togglePlayPause
     * @description Toggles is_playing value and based on this
     * value either runs the audio method play() or pause().
     * Note: the previous value of is_playing is used as calling
     * setIsPlaying() is asynchronous and flow continues to the 
     * conditional statement before the new value of is_playing
     * can return.
     */
    // const togglePlayPause = () => {
    //     const prev_state = is_playing;
    //     setIsPlaying(!prev_state);
    //     if (!prev_state) {
    //         play();
    //     } else {
    //         pause();
    //     }
    // };// end togglePlayPause()

    /**
     * @name changeAudioToPlayhead
     * @description The playhead moves when you click on the progress 
     * bar, so here we update the audio player to the new point.
     */
    const changeAudioToPlayhead = () => {
        audio_player_ref.current.currentTime = progress_bar_ref.current.value;
        setCurrentTime(progress_bar_ref.current.value);
        progress_bar_ref.current.style.setProperty(
            '--seek-before-width',
            `${(progress_bar_ref.current.value / duration) * 100}%`
        );
    };// end changeAudioToPlayhead()

    const timeTravel = (newTime) => {
        progress_bar_ref.current.value = newTime;
        updateCurrentTime();
        changeAudioToPlayhead();
    };
    
    const rewind = (amount = 0) => {        
        timeTravel(Number(progress_bar_ref.current.value) - amount);        
    };
    
    const forward = (amount = 0) => {       
        timeTravel(Number(progress_bar_ref.current.value) + amount);
    };
    
    // const skipToTime = (newTime) => {
    //     timeTravel(newTime);
    //     play();
    // };

    // toggle play / pause when you tap the space bar
    const tapSpaceBar = (e) => {
        if (e.keyCode === 32) {
            const prev_state = is_playing; 
            (!prev_state) ? play() : pause();
        }
    };

    // const handleTabKey = (e) => {
    //     console.log(e.key, e.keyCode);
    //     if (e.key === 'Tab') {
            // e.target.scrollIntoView();
            // const curr_scroll_position = window.scrollY;
  
            // let i = curr_scroll_position;
            // const int = setInterval(function() {
            //     window.scrollTo(0, i);
            //     i -= 10;
            //     if (i <= 0) clearInterval(int);
            // }, 3);
    //     }
    // };

    return (
        <>
        <div className="audio_player_container">

            <header className="audio_player_header">
                <div className="header_content">
                    <div className="listen">
                        <HearingIcon className="listen_icon" /> 
                        <span>Listen to the Story</span>
                    </div>
                </div>
            </header>

            <audio 
                className="audio_player"
                ref={audio_player_ref}
                // src={track_mp4}
                preload="metadata" 
                onLoadedMetadata={onLoadedMetadata}               
            >
                { TrackSources }                
                <track kind="captions" srcLang="en" />
                Your browser does not support the audio element.
            </audio>

            <div className="controls_container">

                <div className="controls_flex">

                    <div className="time_and_progress_bar">

                        <div className="currentTime">
                            {calculateTime(currentTime)}                    
                        </div>

                        <div className="progress_bar_container">
                            <input 
                                ref={progress_bar_ref}
                                type="range" 
                                className="progress_bar" 
                                defaultValue="0"
                                min="0"
                                max="100"
                                // step="0.05"
                                onChange={changeAudioToPlayhead}
                            />
                        </div>

                        <div className="duration">
                            {(duration && !Number.isNaN(duration)) && calculateTime(duration)}
                        </div>

                    </div>

                    <div className="buttons">

                        <button 
                            className="control rewindForward"
                            onClick={() => rewind(20)}
                        >
                            <RewindButton />
                        </button>
                        
                        <button 
                            className="control playPause"
                            onClick={is_playing ? pause : play}
                            onKeyPress={tapSpaceBar}                    
                        >
                            { is_playing ? <PauseButton /> : <PlayButton /> }
                        </button>

                        <button 
                            className="control rewindForward"
                            onClick={() => forward(20)}
                        >
                            <ForwardButton />
                        </button>

                    </div>

                </div>

            </div>

            {/* <footer className="audio_player_footer">
                <div className="footer_content">
                    <div className="listen">
                        <HearingIcon className="listen_icon" /> 
                        <span>Listen to the Story</span>
                    </div>
                </div>
            </footer> */}

        </div>
        </>
    );
};

AudioPlayer.displayName = "AudioPlayer";
AudioPlayer.propTypes = {
    audio_player_ref: PropTypes.oneOfType([        
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]),
    tracks: PropTypes.arrayOf(PropTypes.object),
};
export default AudioPlayer;
