// ==================
// PROJECT SPECIFIC
// ==================



// ==================
// CUSTOM EXCEPTIONS
// ==================

/**
 *
 * @param {string} message message to display for error exception
 */
export function ParameterTypeException(message) {
  this.message = message;
  this.name = "ParameterTypeException";
}

// ==================
// OBJECTS
// ==================

/**
 * Sorts an array of objects by one of the object string properties
 * @param {array} arr
 * @param {string} strProp
 * @return {array} Returns a new sorted array
 */
export function getArrayOfObjectsSortedByStringProperty(arr, strProp) {
  if (!Array.isArray(arr))
    throw new ParameterTypeException(
      "getArrayOfObjectsSortedByStringProperty(): First parameter must be an array."
    );
  if (!checkIsString(strProp))
    throw new ParameterTypeException(
      "getArrayOfObjectsSortedByStringProperty(): Second parameter must be a string."
    );

  const arr_temp = arr.sort((a, b) => {
    // console.log(a, strProp);
    let prop_a = a[strProp].toLowerCase();
    let prop_b = b[strProp].toLowerCase();

    if (prop_a < prop_b) return -1;
    if (prop_a > prop_b) return 1;
    return 0;
  });

  return arr_temp;
} // end getArrayOfObjectsSortedByStringProperty()

/**
 * Compares two objects for equality, including not only same
 * reference but same properties and values.
 * @param {object} object1 first object to compare
 * @param {object} object2 second object to compare
 */
export function isDeepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Determine if passed argument is an object.
 * @param {any} object item to be evaluated as an object
 * @return {boolean} returns true | false
 */
export function isObject(object) {
  return object != null && typeof object === "object";
} // end isObject()

/**
 * Determine if object has any properties.
 * @param {object} obj object to be evaluated for properties
 * @return {boolean} returns true|false
 */
export function isObjectEmpty(obj) {
  for (let x in obj) {
    // if (obj.hasOwnProperty(x))  return false;
    // This still needs testing....
    // https://eslint.org/docs/rules/no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(obj, x)) return false;
  }
  return true;
} // end isObjectEmpty()

/**
 * Transform object into an array
 * @param {object} obj The object to become an array
 * @return {array} Returns new array
 */
export function objectToArray(obj) {
  if (!isObject(obj))
    throw new ParameterTypeException(
      "objectToArray(): Parameter must be an object."
    );
  const keys = Object.keys(obj);
  const keys_len = keys.length;
  let arr_temp = [];
  for (let i = 0; i < keys_len; i++) {
    arr_temp.push(obj[keys[i]]);
  }
  return arr_temp;
} // end objectToArray()

// ==================
// RANDOM VALUES
// ==================

/**
 *
 * @param {integer} min lower value integer to start range
 * @param {integer} max higher value integer for max range, inclusive
 * @return {integer} returns whole number from min to max, inclusive
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

// ==================
// ARRAYS
// ==================

/**
 * Get list of values matching given property_name
 * @param {array} arr_data_set The Array of objects to search
 * @param {*} property_name The property to search for
 * @return {array} Returns list of unique values matching property_name
 */
export function getValuesListFromArrayOfObjects(arr_data_set, property_name) {
  if (!Array.isArray(arr_data_set))
    throw new ParameterTypeException("First parameter must be an array.");
  if (!checkIsString(property_name))
    throw new ParameterTypeException("Second parameter must be a string.");

  let arr_unique = [];
  arr_data_set.forEach((item) => {
    if (!arr_unique.includes(item[property_name])) {
      arr_unique.push(item[property_name]);
    }
  });

  return arr_unique;
} // end getValuesListFromArrayOfObjects()

/**
 * Reorganize items in array in random fashion.
 * @param {array} arr array with items to be reorganized randomly
 * @return {array} returns array with items reorganized randomly
 */
export function shuffle(arr) {
  const arrLen = arr.length;
  for (let i = arrLen - 1; i > 0; i--) {
    // Random index from 0 to i (Math.random return 0 to < 1)
    let j = Math.floor(Math.random() * (i + 1));

    // Swap elements arr[i] and arr[j] using "destructuring
    // assignment" syntax. same can be written as:
    // let t = arr[i]; arr[i] = arr[j]; arr[j] = t
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
}

/**
 * Removes duplicate items from an array.
 * @param {array} arr
 * @return {array} returns an array without duplicate items
 * Note: Duplicate objects are those items referencing the same object;
 * is does not check for similar properties.
 */
export function removeArrayDuplicates(arr) {
  return arr.reduce(function (accumulator, currentValue) {
    if (accumulator.indexOf(currentValue) === -1) {
      // console.log(`currentValue: ${currentValue}`);
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);
}

/**
 *
 * @param {array} arr The array from which to find the random items.
 * @param {integer} numItems Number of items to find in the given array.
 * @param {boolean} unique Whether found items can be duplicates or not.
 * @return {array} Returns array of items from given array. If unique is true,
 * all items will be unique.
 */
// export function getRandomItemsFromArray(arr, numItems = 1, unique = true) {
//   // param checks....
//   if (!Array.isArray(arr))
//     throw new ParameterTypeException("First parameter must be an array.");
//   if (!(Number.isInteger(numItems) && numItems > 0)) {
//     throw new ParameterTypeException(
//       "Second parameter must be a positive integer."
//     );
//   }
//   if (typeof unique !== "boolean")
//     throw new ParameterTypeException("Third parameter must be boolean.");

//   let arrTemp = [];
//   const arrLen = arr.length;
//   /**
//    * We want to find unique items in array. If the number of items
//    * requested is larger than the number of items in the array, it
//    * will cause issues b/c we can't find all unique items. So, we
//    * in such a case, we reset the number of items requested to match
//    * the length of the array (instead of throwing an error).
//    */
//   if (numItems > arrLen) numItems = arrLen;

//   let count = numItems;
//   while (count > 0) {
//     let currItem = arr[Math.floor(Math.random() * arrLen)];
//     if (unique) {
//       let isDuplicate = isCaseItemInArray(arrTemp, currItem);
//       if (!isDuplicate) {
//         arrTemp.push(currItem);
//         count--;
//       }
//     } else {
//       arrTemp.push(currItem);
//       count--;
//     }
//   }
//   return arrTemp;
// } // end getRandomItemsFromArray()

/**
 *
 * @param {array} arr The array to act upon
 * @param {integer} index The index at which to remove an item
 * @param {any} val The value to replace in the array at the given index
 * @return {mutated array} Returns the given array modified
 */
export function updateArrayItem(arr, index, val) {
  // start at index, delete 1 item, add val
  return arr.splice(index, 1, val);
} // end updateArrayItem()

// ==================
// EVENTS
// ==================

/**
 * @name handleKeyDownInternalLink
 * @param {Event} e 
 * @param {string} str_path Relative path
 */
 export const handleKeyDownInternalLink = (e, objHistory, str_path) => {

  if (e.key !== undefined) {

    if (e.key === " " || e.key === "Enter") {
      /**
       * Prevent scroll down with spacebar.
       */
      e.preventDefault();

      objHistory.push(str_path);
      
    }

  }

}; // end handleKeyDownInternalLink()

/**
 * @name handleKeyDownExternalLink
 * @param {Event} e 
 * @param {string} str_path Relative path
 * Example:
 * <Link 
    to={{pathname: "http://www.google.com"}}
    rel="noreferrer noopener"
    type="button"
    className="discover_button"
    onKeyDown={
        (e)=>handleKeyDownExternalLink(
            e, {pathname: "http://www.google.com"}
        )
    }
  >                
    Discover your Inner Panda               
  </Link>
  *
 */
// NOTE: What about useLocation() ???
 export const handleKeyDownExternalLink = (e, objPath) => {

  if (e.key !== undefined) {

    if (e.key === " " || e.key === "Enter") {
      /**
       * Prevent scroll down with spacebar.
       */
      e.preventDefault();

      window.location.assign(objPath.pathname);
      
    }

  }

}; // end handleKeyDownExternalLink()

/**
 * @name handleKeyDownToggle
 * Handle event with KeyboardEvent.key. Enter key or Spacebar should
 * activate onClick, Escape key should close the menu.
 *
 * e.preventDefault() only used with Enter key or Spacebar. This
 * allows tabbing on and off the element (otherwise, if
 * e.preventDefault() is used globally, Tab key will stay stuck
 * on the element).
 * @param {event} e keyboard event
 */
// export const handleKeyDownToggle = (e, toggleState, toggler) => {
//   if (e.key !== undefined) {
//     // console.log('handleKeyDown', e.key);

//     if (e.key === " " || e.key === "Enter") {
//       /**
//        * Prevent scroll down with spacebar and apparently,
//        * Enter key doesn't call toggler() even though the
//        * code enteres this block - that is, unless we use
//        * e.preventDefault() with Enter key. Hmmm....
//        */
//       e.preventDefault();

//       toggler();
//     } else if (e.key === "Escape") {
//       // If menu is open, explicity tell toggler to close menu
//       if (toggleState) toggler();
//     }
//   }
// }; // end handleKeyDownToggle()

/**
 * Handle event with KeyboardEvent.key. Enter key or Spacebar should
 * activate onClick, Escape key should close the menu.
 *
 * e.preventDefault() only used with Enter key or Spacebar. This
 * allows tabbing on and off the element (otherwise, if
 * e.preventDefault() is used globally, Tab key will stay stuck
 * on the element).
 * @param {event} e keyboard event
 * @param {boolean} current_state 
 * @param {function} fx
 */
export const handleKeyDown = (e, current_state, fx) => {
  if (e.key !== undefined) {
    // console.log('handleKeyDown', e.key);

    if (e.key === " " || e.key === "Enter") {
      /**
       * Prevent scroll down with spacebar and apparently,
       * Enter key doesn't call fx() even though the
       * code enteres this block - that is, unless we use
       * e.preventDefault() with Enter key. Hmmm....
       */
      e.preventDefault();

      fx();

    } else if (e.key === "Escape") {

      // If menu is open, explicity close menu
      if (current_state) fx();
      
    }
  }
}; // end handleKeyDown()

// ========================
// DOM Fx
// ========================

export function getEventListenerHandlers() {

  /**
   * This array stores the event listeners as a 
   * convenience to have a way to manage an event 
   * listener list.
   */
  const listeners = [];
  const setListeners = (obj) => {
    listeners.push(obj);
  };

  /**
  * @name addListener 
  * @description Attaches an event listener to the given target and then 
  * updates the listeners array.
  * @param {DOM object} target The DOM object to which to add an event listener.
  * @param {string} e_type ie. 'click', 'scroll', etc.
  * @param {function} handler Fx to run upon event_type
  * @return {void}
  */
  const addListener = (target, e_type, handler) => {
      const obj = {target: target, event_type: e_type, handler: handler};
      target.addEventListener(e_type, handler);
      setListeners(obj);
  };// end addListener()

   // const getListener = (event_type, handler) => {
   //     const listener = allEventListeners.current.find((type, fx) => {
   //         type === event_type && fx === handler
   //     });
       
   //     return listener === undefined ? false : listener;
   // };

  /**
  * @name removeListener 
  * @description Removes an event listener from the given target and then 
  * updates the listeners array.
  * @param {DOM object} target The DOM object from which to remove an event listener.
  * @param {string} e_type ie. 'click', 'scroll', etc.
  * @param {function} handler Fx to run upon event_type
  * @return {void}
  */
  const removeListener = (target, e_type, handler) => {
      
    target.removeEventListener(e_type, handler);

    listeners.splice(listeners.findIndex((target_obj, type, fx) => {
        target_obj === target && type === e_type && fx === handler
    }), 1);
  
  };// end removeListener()

  return {
    listeners: listeners,
    addListener: addListener,
    removeListener: removeListener,
  }

}// end getEventListenerHandlers()

/**
 * @name isDOMElement
 * @description Determines if given object is a DOM node.
 * @param {object} DOMobj DOM element
 * @returns {boolean} true | false
 */
 export function isDOMElement(DOMobj) {
  // return element instanceof Element || element instanceof Document;  
  
  try {
      DOMobj.cloneNode(false);
  } catch (e) {
      return false;
  }
  
  return true;

}// end isDOMElement()

// ========================
// FORMS
// ========================

/**
 * @name togglePasswordFieldVisibility
 * @param {input element} objField
 * @return {string} "password" | "text" to update input field 
 * type property value.
 */
 export function togglePasswordFieldVisibility(objField) {    
  if (objField.type === "password") {
    objField.type = "text";
  } else {
    objField.type = "password";
  }
  return objField.type;
}// end togglePasswordFieldVisibility()

/**
 * @name setElementValue
 * @param {HTMLInputElement} element 
 * @param {string} value
 */
 export function setElementValue(element, value) {
    
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(element, value);

  const event = new Event("input", { bubbles: true });
  element.dispatchEvent(event);

}// end setElementValue()

/**
 * @name getElementValue
 * @param {HTMLInputElement} element 
 * @return {string} the HTMLInputElement's value
 */
export function getElementValue(element) {

  const nativeInputValueGetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).get;

  return nativeInputValueGetter.call(element);
  // return Object.getOwnPropertyDescriptors(element);

}// end getElementValue()

/**
 * @name htmlToElement
 * @description Converts HTML string to DOM element.
 * @param {string} str_in 
 * @return {DOM Element} A DOM element which can be 
 * inserted into the DOM.
 */
export const htmlToElement = (str_in) => {
  
    const placeholder = document.createElement('div');
    placeholder.innerHTML = str_in;

    
    // return (placeholder.children.length > 0) ? placeholder.firstElementChild : undefined;
    return (placeholder.children.length > 0) ? placeholder.childNodes : undefined;

};// end htmlToElement()

/**
 * A valid email address has four parts:
 * Recipient name: max 64 chars in length, plus:
 * - Uppercase and lowercase letters in English (A-Z, a-z)
 * - Digits from 0 to 9
 * - Special characters such as ! # $ % & ' * + - / = ? ^ _ ` { |
 *      Note: A special character cannot appear as the first or last character in an email address
 *      or appear consecutively two or more times. (?!.*(.)\1+).*
 *      Excluded: " ( ) , : ; < > @ [ \ ] 
 * @ symbol
 * Domain name - maximum of 253 characters and consist of:
 * - Uppercase and lowercase letters in English (A-Z, a-z)
 * - Digits from 0 to 9 
 * - A hyphen (-)
 * - A period (.)  (used to identify a sub-domain; for example,  email.domainsample)
 * Top-level domain (ie. .com, .net)
 * The following Regexp checks for all the above except for consecutive special characters. As I researched
 * email regexp I came to understand that it didn't matter much for it to be perfect as I'll be 
 * sending a confirmation email to the new member once they sign up. This fx returns an object with
 * props describing the length of the email string and whether it tests as valid. The local script
 * can then decide what to do with this information. 
 * 
 */
/**
 * @name getEmailValidationInfo
 * @param {string} strEmail 
 * @returns {object} {int, boolean}
 * @dependency getActualStringLength() This fx depends upon 
 * getActualStringLength() to get accurate string length.
 */
 export function getEmailValidationInfo(strEmail) {
  const objRet = {
      length: getActualStringLength(strEmail),
      isValid: /^[A-Z0-9][A-Z0-9.!#$%&'*+/=?^_‘{|}~-]{0,63}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(strEmail),
  };

  return objRet;

}// end getEmailValidationInfo()

/**
 * @name generateStrongPassword
 * 1. Randomize length of password from intMinLength to intMaxLength
 * 2. Randomize which passKey key is searched first
 * 3. Select a random number of chars from that key
 * These similar chars (0, 1, l, O, o) have already been removed from the 
 * passKeys values.
 * Repeat 2, 3 until x length is reached
 * [Calling function if it wishes can do the following recursively]
 * 4. Compare against zxcvbn - if not 4/4 (or whatever the minimum score 
 * strength should be) score, repeat 2, 3 until 4/4
 * @param {integer} intMinLength 
 * @param {integer} intMaxLength 
 * 
 */
 export function generateStrongPassword(intMinLength = 11, intMaxLength = 16) {

  console.time('generateStrongPassword()');

  let arrTemp = [];

  const minPasswordLength = intMinLength;
  const maxPasswordLength = getRandomIntInclusive(minPasswordLength, intMaxLength);
  const passwordLength = getRandomIntInclusive(minPasswordLength, maxPasswordLength);
  console.log(`minPasswordLength: ${minPasswordLength} 
              maxPasswordLength: ${maxPasswordLength} 
              passwordLength: ${passwordLength}`);

  // Some similar values removed (ie. letter O and number 0)
  const passKeys = {
      symbols: "~`!@#$%^&*()-_=+|\\{}[]\"':;?/<>,.",
      numbers: "23456789",
      lowercase: "abcdefghijkmnpqrstuvwxyz",
      uppercase: "ABCDEFGHIJKLMNPQRSTUVWXYZ"
  };

  const arrPassKeys = Object.keys(passKeys);
  const passKeysLen = Object.keys(passKeys).length;        

  // Base case: keep adding to arrTemp until length === passwordLength
  while (passwordLength > arrTemp.length) {

      // console.log(arrTemp, passwordLength);               

      // Determine randomly which passKeys property to select values from. 
      let rndInt = getRandomIntInclusive(0, passKeysLen-1);
      let whichPassKeyValue = passKeys[arrPassKeys[rndInt]];
      let whichPassKeyValueLen = whichPassKeyValue.length;

      // Determine random number of chars to select from those values.   
      let numCharsToSelect = getRandomIntInclusive(1, 4);

      // Modify number of chars selected to equal passwordLength            
      if (arrTemp.length < passwordLength) {
          if ((arrTemp.length + numCharsToSelect) > passwordLength) {
              // Just pick the few chars left needed to equal passwordLength....
              numCharsToSelect = passwordLength - arrTemp.length;
          }
      }

      // Select those random chars
      for (let i = 0; i < numCharsToSelect; i++) {
          let rndChar = whichPassKeyValue.charAt(Math.floor(Math.random() * whichPassKeyValueLen));
          arrTemp.push(rndChar);
      }

      // Remove array duplicates for a stronger password
      arrTemp = (arrTemp.length >= 2) ? removeArrayDuplicates(arrTemp) : arrTemp;       

  }// end while

  console.timeEnd('generateStrongPassword()');

  console.log(`Final arrTemp: ${arrTemp.join('')} [${arrTemp.length}]`);

  return arrTemp.join('');

}// end generateStrongPassword()

// ==================
// STRINGS
// ==================

/**
* Fx for escaping user input when putting that input into a regexp such as in getInputValuesAsQueryString(),
* below. 
* @param {string} string 
*/
export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}// end escapeRegExp()

/**
 * @name getActualStringLength
 * @param {string} str_value The string to determine the length of
 * @returns length of actual number of characters in str_value, not code units
 * 
 * Example:
 * const str = "🎉🔥✨💯";
 * console.log(str.length, getActualStringLength(str)); returns 7, 4
 */
 export function getActualStringLength(str_value) {
  return [...str_value].length;// This way iterates over characters, not code units
}// end getActualStringLength()

/**
 * Checks if a value returns typeof 'string'
 * @param {any} val parameter to check
 * @return {boolean} true | false
 */
export function checkIsString(val) {
  return typeof val === "string" ? true : false;
} // end checkIsString()

/**
 * Method to replace spaces with underscores
 * @param {string} val
 * @return {string} Returns string with spaces replaced with underscores
 */
export function addUnderscores(val) {
  if (!checkIsString(val))
    throw new ParameterTypeException("Parameter must be of type, 'string'.");
  return val.split(" ").join("_");
} // end addUnderscores()

/**
 * Method to remove a given character from a string
 * @param {string} val
 * @param {string} charToRemove
 * @return {string} Returns string with the given charToRemove removed
 */
export function removeCharacter(val, charToRemove) {
  if (!checkIsString(val))
    throw new ParameterTypeException(
      "First parameter must be of type, 'string'."
    );
  if (!checkIsString(charToRemove))
    throw new ParameterTypeException(
      "Second parameter must be of type, 'string'."
    );

  let arrTemp = val.split("");
  let arrFiltered = arrTemp.filter((item) => item !== charToRemove);

  return arrFiltered.join("");
} // end removeCharacter()
/**
 * @name htmlEncode
 * @param {string} str_in 
 * @returns A new string with the characters listed in 
 * the switch (HTML characters) converted to HTML entities.
 */
export function htmlEncode(str_in) {
  // check for input as str

  const str_out = (str_in.trim()).replace(
    /[&"<>]/g,
    (char) => {                                            
        switch(char) {
            case '&':  
                return '&amp;';
            case '<':
                return '&lt;';
            case '>':  
                return '&gt;';
            case '"': 
                return '&quot;';
            default: 
                return "";
        }
    }
  );

  return str_out;

}// end htmlEncode()

/**
 * @name htmlDecode
 * @param {string} str_in 
 * @returns A new string with all HTML entities converted
 * back to HTML characters. ie. &gt; => >
 */
export const htmlDecode = (str_in) => {

    const placeholder = document.createElement('textarea');
    placeholder.innerHTML = str_in;

    return placeholder.value;

};// end htmlDecode()                                

// ========================
// NUMBERS & MONEY
// ========================

export const formatMoney = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(amount);
};

export const removeDoubleZeros = (amount) => amount.replace(/\.00$/, '');


// ========================
// MISCELLANEOUS
// ========================

/**
 * @name debounce
 * @description debounce delays execution of a function by given milliseconds. To use,
 * wrap the function to debounce inside: 
 * const debouncedHandleResize = debounce( () => { ...method to debounce }, 30 );
 * @param {function} fn The function to delay execution of
 * @param {integer} ms Number of milliseconds to delay
 * @returns fn is executed at end of setTimeout.
 */
export function debounce(fn, ms) {

  let timer;
  return () => {
      clearTimeout(timer);
      timer = setTimeout( () => {
          timer = null
          fn.apply(this, arguments)
      }, ms);
  };

}// end debounce()

/**
 * @name getWindowDimensions
 * @description Cross-browser determination of current width 
 * and height of browser window.
 * @returns {object} {width, height}
 */
export function getWindowDimensions() {

  const w = window;
  const d = document;
  const documentElement = d.documentElement;
  const body = d.getElementsByTagName('body')[0];
  const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
  const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

  return { width, height };

}// end getWindowDimensions()

/**
 * @name handleScrollToTopAndResetFocus
 * Handles scrolling the page to the top (0, 0)
 * (handles Y axis only) and also resets focus
 * to document (to aid accessibility).
 * @return {void} 
 */
export function handleScrollToTopAndResetFocus() {

  const curr_scroll_position = window.scrollY;
  
  let i = curr_scroll_position;
  const int = setInterval(function() {
      window.scrollTo(0, i);
      i -= 10;
      if (i <= 0) clearInterval(int);
  }, 3);
  
  // Reset focus to root of document (HTML element)
  const root = document.documentElement;
  root.tabIndex = 0;
  root.focus();

}// end handleScrollToTopAndResetFocus()
