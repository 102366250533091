
import * as React from "react";
import PropTypes from 'prop-types';

// HelperFx
import { ParameterTypeException, checkIsString } from './../functions/helperFunctions';

/**
 * Creates DOM element to be used as React root.
 * @returns {HTMLElement}
 */
 function createContainerElement(id, type) {
    const root_container = document.createElement(`${type}`);
    root_container.setAttribute('id', id);
    return root_container;
  }
  
  /**
   * Appends element as last child of body.
   * @param {HTMLElement} root_elem 
   */
//   function addRootElement(root_elem) {
//     document.body.insertBefore(
//       root_elem,
//       document.body.lastElementChild.nextElementSibling,
//     );
//   }

/**
 * 
 * @param {object} props object with the following optional key/values:
 * @param {DOM node} parent_element [document.body] The parent node which 
 * to attach the newly created container element. 
 * @param {string} container_element_type ['DIV'] The type of element 
 * to create (ie. DIV, SPAN)
 * @param {string} container_element_id ['defaultImagePortalContainer'] The 
 * value to assign to the id attribute of the container element.
 * @returns {DOM node} The newly created element in which the container
 * will be placed.
 */
function useCreatePortalContainer(props = {  
    container_parent_element_id: 'defaultPortalParentContainer',  
    container_element_id: 'defaultPortalContainer',
    container_element_type: 'DIV'
}) {

    // Parameter checking
    if (!checkIsString(props.container_parent_element_id) || 
        props.container_parent_element_id === '') {
        throw new ParameterTypeException(
            "useCreatePortalContainer: container_parent_element_id prop must be a non-empty string (the id of the parent element). If no value is provided for this key, the container element will be appended to the document body element."
        );
    }

    if (!checkIsString(props.container_element_id)) {
        throw new ParameterTypeException(
            "useCreatePortalContainer: container_element_id prop must be a string."
        );
    }

    if (!checkIsString(props.container_element_type)) {
        throw new ParameterTypeException(
            "useCreatePortalContainer: container_element_type prop must be a string."
        );
    }

    // Initialize ref for container DOM element
    const container_element_ref = React.useRef(null);

    /**
     * useEffect() runs on second render and will 
     */
    React.useEffect(function setUpElement() {

        // Look for existing target dom element to append to
        const existing_parent = document.querySelector(`#${props.container_parent_element_id}`);        

        /**
         * If the given DOM element does NOT already exists, create it. 
         */
        const container_parent_element =  existing_parent ||               
            createContainerElement(
                props.container_parent_element_id, props.container_element_type
            );
        
        // Append the newly created DOM element to the document BODY element.
        if (!existing_parent) {            
            document.body.appendChild(container_parent_element);
        }

        // Add the detached element to the parent
        container_parent_element.appendChild(container_element_ref.current);

        return () => {
            container_parent_element.removeChild(container_element_ref.current);
        };
    
    }, [props.container_parent_element_id, props.container_element_id, props.container_element_type]);

    // Lazy evaluation, as first render must contain DOM element.
    function getRootElement() {

        if (container_element_ref.current === null) {
            container_element_ref.current =                
                createContainerElement(`${props.container_element_id}`, `${props.container_element_type}`);
        }
        return container_element_ref.current;

    }// end getRootElement()

    return getRootElement();

}

useCreatePortalContainer.displayName = "useCreatePortalContainer";
useCreatePortalContainer.propTypes = {
    parent_element: PropTypes.node,
    container_element_type: PropTypes.string,
    container_element_id: PropTypes.string,
}

export default useCreatePortalContainer;
