
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from '@mui/material/styles';
import theme from './../styles/theme';

const MuiThemeContext = createContext();

function MuiThemeContextProvider({ children }) {

    const [mode, setMode] = useState('dark');

    const toggleColorMode = (new_mode) => {
        setMode(new_mode);
        theme.palette.type = mode;
    };
    
    // console.log(mode, theme.palette.type);

    return (
        <MuiThemeContext.Provider value={{mode, toggleColorMode}}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </MuiThemeContext.Provider>
    )
}

MuiThemeContextProvider.displayName = "MuiThemeContextProvider";
MuiThemeContextProvider.propTypes = {
    children: PropTypes.node,
}

export { 
    MuiThemeContext as MuiThemeContextConsumer,
    MuiThemeContextProvider,
}
