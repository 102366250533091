import React from "react";
import { Helmet } from 'react-helmet-async';

// Page Header
import HeaderMain from './../components/HeaderMain';

// Sections
import Introduction from "../components/Introduction";
import Setup from './../components/Setup';
import Massacre from './../components/Massacre';
import Aftermath from './../components/Aftermath';
import AfterYears from './../components/AfterYears'
import Conclusion from "../components/Conclusion";

// Helper Functions
import DisableRightClickAndDragOnImages from './../components/DisableRightClickAndDragOnImages';

function Home() { 

  /**
   * Prevent ability to bring up context menu and drag images.
   */
  DisableRightClickAndDragOnImages();

  return (
    <>
      <Helmet>
          <title>1984 Home Page</title>          
      </Helmet>

      <div className="container_main">
        <HeaderMain />
      </div>

      <Introduction />
      <Setup />
      <Massacre />
      <Aftermath /> 
      <AfterYears />
      <Conclusion />

    </>
  );
}

export default Home;
